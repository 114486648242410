.inputLabel {
  color: var(--gray-1);
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
}
.requiredInput:before {
  content:" *";
  color: red;
}
.input, .select {
  display: block;
  width: 100%;
  height: 40px;
  color: #141414;
  margin-top: var(--spacing-half);
  margin-bottom: var(--spacing-3);
  border: 1px solid var(--gray-5);
  border-radius: 3px;
  outline: none;
  font-size: 16px;
  line-height: 19px;
  padding: 0 12px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input:focus, .select:focus {
  border-color: var(--blue-3);
}
.input::placeholder, select:invalid {
  color: #D3D3D3;
}

.select {
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - var(--spacing-2));
  background-position-y: var(--spacing-1);
}