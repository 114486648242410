@font-face {
  font-family: 'SF Pro Display';
  src: url('./../font/FontsFree-Net-SFProDisplay-Regular.ttf');
}
.silverBadge {
  
  p {
    margin: 0px;
    padding: 0px;
    font-family: 'SF Pro Display';
  }
  
p{
  margin-bottom:0px
}
  #mainDiv {
    max-width: 600px;
    // min-width: 375px;
    // min-width: 30px;
    background: #ffffff;
    margin: auto;
    /* padding: 30px 15px; */
  }

  .imgDiv {
    width: 87%;
    height: auto !important;
    margin: auto;
    text-align: center;
    margin-top: 3px;
    margin-bottom: 14px;
  }

  .IconButton {
    width: 30px;
    height: 30px;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 0;
  }

  .IconDiv {
    display: flex;
    justify-content: space-between;
  }
  .HeadingLight {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    color: #4f4f4f;
    align-items: center;
    justify-content: center;
    p {
      text-align: center;
    }
    .p2 {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height, or 150% */

      text-align: center;

      color: #4f4f4f;
    }
  }

  .Username {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    color: #4f4f4f;
  }
  .text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #4f4f4f;
    text-align: left;
    width: 100%;
  }

  .ShadowDiv {
    width: 95%;
    background: #ffffff;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 19px auto;
    padding: 20px 10px;
    margin-top: 18px;
  }
  .TextDivWithCheckBox .text {
    width: 90%;
    color: #000000;
    margin: 0px auto;
  }
  .TextDivWithCheckBox {
    display: flex;
    margin: 8px auto;
  }
  .buttonsMainDiv {
    background: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    left: 0;
    right: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 7px 11px 9px rgba(0, 0, 0, 0.05);
  }
  .buttonTransparent {
    height: 40px;
    width: 96%;
    background: #ffffff;
    box-shadow: 0px 7px 11px 9px rgba(0, 0, 0, 0.05);
    border-radius: 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #42c0f5;
    text-align: center;
    border: 0px;
    outline: 0px;
    margin-top: 15px;
  }
  .buttonBlue {
    height: 40px;
    width: 96%;
    background: #42c0f5;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
    text-align: center;
    border: 0px;
    outline: 0px;

    margin-top: 15px;
    margin-bottom: 10px;
  }
  .footerText {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #000000;
  }
  .midsmallText {
    width: 84%;
    margin: auto;
  }
  @media only screen and (max-width: 500px) {
    .midsmallText {
      width: 100% !important;
    }
  }
  p {
    font-family: 'SF Pro Display';
  }
  * {
    font-family: "SF Pro Display" !important;
  }
}
