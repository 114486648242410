.WhiteNav {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 12px rgba(33, 103, 132, 0.12);
    margin-bottom: 0px;
    z-index: 2;
    position: sticky;

    .innerNav {
        max-width: 1300px;
        width: 100%;

        img {
            height: 31px;
            margin: 24px;

            @media screen and (max-width: 600px) {
                margin: 25px 16px 23px;
            }
        }
    }

    .mobDownload {
        display: none;

        @media screen and (max-width: 600px) {
            display: block;
        }

        .downloadBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 4px;

            // button{
            img {
                width: 33px;
                height: 34px;
                margin-right: 12px;
            }

            // }
        }
    }

    .reviewMobSlider {
        .reviewSlide {
            .innerSlide {
                a {
                    font-size: 24px;
                }
            }
        }
    }
}

.navV5 {
    // padding: 27px 20px;
    background: #F3FCFF;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    // position: absolute;
    width: 100%;
    // rough
    position: absolute;
    background: transparent;
    padding: 20px;

    @media screen and (max-width: 600px) {
        position: fixed;
        top: 0px;
        background: #F3FCFF;
    padding: 18px;
    // background: #e5f8ff;
    }

    .innerNav {
        max-width: 1300px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media screen and (max-width: 600px) {
            justify-content: flex-start;
        }

        .logoDiv {
            height: 80px;
            // padding: 21px 13px;

            // width: 241px;
            @media screen and (max-width: 600px) {
                width: calc(100% - 65px - 34.39px);
                height: auto;
            }

            img {
                height: 100%;

                @media screen and (max-width: 600px) {
                    width: 100%;
                }
            }
        }
    }

    .mobDownload {
        display: none;

        @media screen and (max-width: 600px) {
            display: block;
        }

        .downloadBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 4px;

            // button{
            img {
                width: 33px;
                height: 34px;
                margin-right: 12px;
            }

            // }
        }
    }
}