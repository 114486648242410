@font-face {
  font-family: "SF Pro Display";
  src: url("./../font/FontsFree-Net-SFProDisplay-Regular.ttf");
}
@font-face {
  font-family: "SF Pro Display Medium";
  src: url("./../../../assets/fonts/SFPRODISPLAYMEDIUM.OTF");
}

.bronzeBadge {
  p {
    margin: 0px;
    padding: 0px;
    font-family: "SF Pro Display";
  }

  p {
    margin-bottom: 0px;
  }

  #mainDiv {
    max-width: 600px;
    min-width: 375px;
    background: #ffffff;
    margin: auto;
    /* padding: 30px 15px; */
    font-family: "SF Pro Display";
  }

  .imgDiv {
    width: 87%;
    height: auto !important;
    margin: auto;
    text-align: center;
    margin-top: 3px;
    margin-bottom: 14px;

    img {
      width: 69%;
    }
  }

  .Image {
    width: 100% !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100vw !important;
    }
  }

  .IconButton {
    width: 30px;
    height: 30px;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 0;
  }

  .IconDiv {
    display: flex;
    justify-content: space-between;
  }

  .HeadingLight {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    color: #4f4f4f;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      /* identical to box height, or 144% */


      color: #4F4F4F;
    }

    .p2 {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      /* or 150% */

      text-align: center;

      color: #4f4f4f;
      margin-bottom: 10.92px;
    }
  }

  .Username {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    color: #4f4f4f;
    margin-bottom: 16px !important;
    padding: 0px 14px;

    a {
      font-weight: 700;
      color: #42c0f5;
    }
  }

  .text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #4f4f4f;
    text-align: left;
    width: 100%;
  }

  .font_weight {
    font-weight: 700 !important;
  }

  .text.text-center {
    color: #000;
    // font-size: 16px;
    // line-height: 21px;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;

    i {
      font-style: italic;
    }
  }

  .txt-center {
    text-align: left;
  }

  .text-Left {
    text-align: left !important;
  }

  .white-space {
    white-space: nowrap;
  }

  .margin-Bottom {
    margin-bottom: 10px !important;
  }

  .text.fs-6 {
    color: #000;
    // font-size: 16px;
    font-size: 14px;
    line-height: 21px;
    // margin-bottom: 30px;
    padding: 0px 14px;

    b {
      color: #b88752;
      text-transform: uppercase;
    }
  }

  .ShadowDiv {
    background: #ffffff;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 30px 7px 30px;
    padding: 13px 15px;

    // margin-top: 18px;
    .text.fs-6 {
      padding: 0px !important;
      margin: 0px 0px 0px 0px !important;
      width: 100% !important;
    }
  }

  .stpesText {
    padding: 0px !important;
    margin: 0px 0px 0px 10px !important;
    width: 100% !important;
  }

  .applicaList {
    margin-bottom: 16px;
  }

  .TextDivWithCheckBox .text {
    width: 90%;
    // width: 94.5%;
    color: #000000;
    margin: 0px auto;
  }

  .TextDivWithCheckBox {
    display: flex;
    margin: 0px auto;
  }

  .buttonsMainDiv {
    background: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    left: 0;
    right: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 7px 11px 9px rgba(0, 0, 0, 0.05);
  }

  .buttonTransparent {
    height: 40px;
    width: 96%;
    background: #ffffff;
    box-shadow: 0px 7px 11px 9px rgba(0, 0, 0, 0.05);
    border-radius: 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #42c0f5;
    text-align: center;
    border: 0px;
    outline: 0px;
    margin-top: 15px;
  }

  .buttonBlue {
    height: 40px;
    width: 96%;
    background: #42c0f5;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
    text-align: center;
    border: 0px;
    outline: 0px;

    margin-top: 15px;
    margin-bottom: 10px;
  }

  .footerText {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #000000;
  }

  .midsmallText {
    width: 84%;
    margin: auto;
  }

  .freeText {
    margin-bottom: 8px !important;
  }

  .cliffText {
    margin-bottom: 29px !important;
  }

  .stepCount {
    margin-right: 4px;
  }

  .youAre1Bronze {
    // margin-bottom: 30px;
  }

  .stepCount {
    font-size: 14px;
  }

  .reDetail {
    padding: 13px 9px !important;

    .fs-6 {
      &:not(:last-child) {
        margin-bottom: 16px !important;
      }

      b {
        text-transform: initial;
      }
    }

    .TextDivWithCheckBox:not(:last-child) {
      margin-bottom: 16px !important;
    }
  }

  @media only screen and (max-width: 500px) {
    .midsmallText {
      width: 100% !important;
    }
  }

  p {
    font-family: "SF Pro Display";
  }

  * {
    font-family: "SF Pro Display" !important;
  }
}