/* DeleteAccount.css */

.delete-account-container {
    max-width: 450px;
    margin: 100px auto;
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .instructions {
    margin-bottom: 20px;
  }
  
  .form-label {
    display: block;
    margin-bottom: 15px;
    text-align: left;
  }
  
  .input-field {
    width: 100%;
    padding: 8px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #42BFF5;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #42BFF5;
  }
  
  .success-message {
    margin-top: 20px;
    color: green;
  }
  