.main {
    width: 100%;
    background-color: white;
    height: max-content;
}

button.export {
    border-radius: 10px;
    background-color: #42c0f5;
    color: #fff;
    margin-bottom: 10px;
    margin-inline: 10px;
    margin-top: 10px;
}

.emptyList{
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.emptyList h2 {
    text-align: center;
    color: #42c0f5;
}