@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
#root{
  overflow: hidden;
}
body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
}

#root {
  background-color: #F9FDFF;
}
