.ExtraPages {
  ::after,
  ::before {
    box-sizing: unset;
    // border: unset;
  }

  .App {
    font-family: "SF Pro Display";
    min-height: 100vh;
    background-color: #42c0f5;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    // font-size: bold;

    .app-header {
      background-color: #fff;
      width: 100%;
      padding: 16px 0;
    }
    .header-content {
      max-width: 1140px;
      // padding: 0 16px;
      padding-bottom: 4px;
      margin: auto;
    }
    .app-footer {
      background: #3d3c3c;
      padding: 30px 0;
    }
    .app-footer p {
      font-family: "SF Pro Display";
      margin: 0;
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      line-height: 12px;
      padding: 0 32px;
    }
    .Mail-content {
      width: 357px;
      height: 373px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .WeeklyUpdates-content {
      width: 357px;
      height: 305px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media screen and (max-width: 600px) {
      .WeeklyUpdates-content {
        width: 280px;
      }
      .Mail-content {
        width: 280px;
      }
      .header-content {
        margin-left: 16px;
      }
    }
    .card-footer123 {
      width: 70%;
    }
    .card-footer123 a {
      margin-bottom: 8px !important;
      cursor: pointer !important;
    }
    .card-footer123 p {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0 !important;
    }
    .mb {
      margin-bottom: 60px;
    }
    .box-content {
      background-color: #fff;
      border-radius: 4.87218px;
      margin: auto;
      text-align: center;
      position: relative;
      /* width: 357px;   */
      /* width: 290px;
  padding: 40px; */
      /* height: 305px; */
    }
    .box-content h5 {
      font-weight: 700;
      font-size: 24px;
      line-height: 22px;
      margin: 16px 0;
      color: black;
    }
    .card-footer123 {
      position: absolute;
      bottom: 10px;
    }
    .box-content p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      max-width: 230px;
      margin: auto;
      letter-spacing: 0.2px;
      font-family: "SF Pro Display";
      margin-bottom: 30px;
      color: #000000;
    }
    .box-content p a,
    .box-content h6 {
      font-family: "SF Pro Display";
      color: #42c0f5;
      letter-spacing: 0.2px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 16px 0;
    }
    .box-content hr {
      // border-top: unset;
      border-top: 1px solid rgba(0, 0, 0, 0.4);
      margin: 16px 0;
    }
  }
}

