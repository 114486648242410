.emailVerificationScreen {
  min-height: calc(100vh - 90px);
}
.row {
  display: block;
  padding: 0 var(--spacing-4);
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
}
.card {
  max-width: 1280px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  margin: var(--spacing-4) auto;
  padding: var(--spacing-5);
  border: 1px solid #DFE0E1;
  border-radius: 4px;
  box-sizing: border-box;
}
.card h1 {
  margin: auto;
  color: var(--gray-1);
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  margin-bottom: var(--spacing-2);
}

.buttonWrapper {
  width: 100%;
  max-width: 368px;
  margin: auto;
}