.splashMain {
  min-height: calc(100vh - 90px);
  --section-bg-border-radius: 110px;
}

.splashMain > section {
  width: 100%;
}

.modal_custom {
  background-color: #42c0f5;
  color: #fff;
  border-radius: 15px;
}

.h4Whie {
  color: #fff;
}

.sectionContent {
  width: 1280px;
  max-width: 100%;
  margin: auto;
  padding: 0 var(--spacing-4);
  box-sizing: border-box;
  display: flex;
  height: 100%;
}
.sectionOne .sectionContent {
  height: 90%;
}
.blueBar {
  border-top: 5px solid var(--blue-3);
  width: 96px;
  margin-bottom: var(--spacing-4);
}

.sectionOne {
  height: 500px;
  display: flex;
}

.sectionOne .sectionContent {
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.sectionOne h1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0.2px;
  color: var(--gray-1);
  margin-bottom: var(--spacing-3);
}
.sectionOne h2 {
  font-size: 35px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 0.2px;
  color: var(--gray-1);
  margin-bottom: var(--spacing-3);
}
.sectionOne p {
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 0.13px;
  color: var(--gray-2);
}

.sectionTwo {
  background-color: var(--blue-3);
  /* height: 752px; */
  height: 570px;
  border-radius: 0 0 var(--section-bg-border-radius) 0;
  color: var(--white);
}

.sectionTwo .textWrapper {
  /* margin: auto 0; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.sectionTwo p {
  font-size: 28px;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 30px !important;
}
.sectionTwo .textWrapper2 {
  /* font-weight: 800; */
  font-size: 28px;
  line-height: 38px;
  margin-top: 43px;
}
.sectionTwo h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  margin-bottom: 10px;
}

.sectionTwo .imageWrapper {
  flex-grow: 1;
  position: relative;
  margin-left: 220px;
  background-image: url(../../assets/images/triangle.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: 150px;
  margin-top: -7%;
  animation: mymove 15s infinite;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
@keyframes mymove {
  0% {
    background-position: 0px 50px;
  }
  25% {
    background-position: 0px 100px;
  }
  50% {
    background-position: 0px 150px;
  }
  75% {
    background-position: 0px 170px;
  }
  100% {
    background-position: 0px 50px;
  }
  75% {
    background-position: 0px 170px;
  }
  50% {
    background-position: 0px 150px;
  }
  25% {
    background-position: 0px 100px;
  }
  0% {
    background-position: 0px 50px;
  }
}

.sectionTwo img {
  position: absolute;
  /* bottom: 14px; */
  /* left: 50%; */
  /* transform: translate(-50%, 0); */
  /* width: 400px; */
  object-fit: scale-down;
  height: 100vh;
  width: 100%;
  /* top: -18%; */
}

/* section 3~6 shared */
.sectionThree,
.sectionFour,
.sectionFive,
.sectionSix {
  height: 800px;
}

.sectionThree .sectionContent,
.sectionFour .sectionContent,
.sectionFive .sectionContent,
.sectionSix .sectionContent {
  align-items: center;
}

.sectionThree h1,
.sectionFour h1,
.sectionFive h1,
.sectionSix h1 {
  font-size: 2.2em;
  font-weight: 600;
  line-height: 50px;
  margin-bottom: 25px;
}

.sectionThree p,
.sectionFour p,
.sectionFive p,
.sectionSix p {
  font-size: 23px;
  line-height: 45px;
  font-weight: 600;
}

.sectionThree img,
.sectionFour img,
.sectionFive img,
.sectionSix img {
  width: 340px;
  object-fit: scale-down;
}

/* section 3, 5 shared */
.sectionThree .imageWrapper,
.sectionFive .imageWrapper {
  margin-right: 128px;
}
.imageWrapper5 {
  margin-left: 200px;
}
/* section 4, 6 shared */
.sectionFour .imageWrapper,
.sectionSix .imageWrapper {
  margin-left: 128px;
}

.sectionFour,
.sectionSix {
  background-color: #f7f4f4;
  border-radius: var(--section-bg-border-radius) 0 var(--section-bg-border-radius) 0;
}

/* section 7, 8 shared */
.sectionSeven .sectionContent,
.sectionEight .sectionContent {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.sectionSeven .blueBar,
.sectionEight .blueBar {
  margin-bottom: 12px;
}

.sectionSeven h1,
.sectionEight h1 {
  font-size: 42px;
  font-weight: 600;
  line-height: 50px;
  margin-bottom: 32px;
  text-align: center;
}

.sectionEight p {
  font-size: 38px;
  /* font-weight: bold; */
  line-height: 56px;
}
.sectionEight .textWrapper {
  text-align: center;
  /* width: 685px; */
}
.sectionEight {
  flex-direction: column;
}
.sectionEight .sectionContent {
  height: 80%;
}
/* section 7 */
.sectionSeven {
  height: 600px;
  display: flex;
}

.sectionSeven .testimonials {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.sectionSeven .testimonials div {
  text-align: center;
  width: 100%;
  font-style: italic;
  font-size: 16px;
  line-height: 32px;
  margin: auto;
  outline: none;
}
.sectionSeven .testimonials div p {
  width: 291px;
  margin: auto;
}

.sectionSeven .testimonials svg {
  color: #ffb508;
}

/* section 8 */
.sectionEight {
  height: 580px;

  display: flex;
  background-color: #f7f4f4;
  border-radius: var(--section-bg-border-radius) 0 0 0;
}

.sectionEight h1 b {
  font-weight: 600;
}

.sectionEight .socialMediaBtns {
  margin-top: 54px;
  display: flex;
  width: 310px;
  justify-content: space-between;
}
.inputContainer {
  border: 2px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  border-radius: 40px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0 0px;
}
.inputContainer input {
  font-size: 12px;
  line-height: 16px;
  background-color: transparent;
  outline: none;
  color: #ffffff;
  border: none;
  font-size: 20px;
  line-height: 27px;
  padding-left: 12px;
  width: 65%;
}
.inputContainer input::placeholder {
  color: #ffff;
}
.inputContainer button {
  background: #ffffff;
  border-radius: 40px;
  height: 100%;
  width: 35%;
  align-self: flex-end;
  justify-self: flex-end;
  border: none;
  line-height: 16px;
  font-size: 33px;
  font-weight: 400 !important;
  text-align: center;
  color: #42c0f5;
  outline: none;
}
.sectionEight .inputContainer {
  border: 2px solid #42c0f5;
  height: 60px;
  width: 510px;
  margin: 37px auto 10px;
}
.sectionEight .inputContainer input {
  color: #42c0f5;
}
.sectionEight .inputContainer input::placeholder {
  color: #42c0f5;
}
.sectionEight .inputContainer button {
  background: #42c0f5;
  color: #fff;
}
.sectionEight .socialMediaBtns a {
  display: flex;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: var(--blue-3);
  color: var(--white);
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1130px) {
  .sectionTwo {
    height: 620px;
  }
  .sectionTwo .imageWrapper {
    margin-left: 24px;
  }
  /* .sectionTwo img {
    width: 330px;
  } */
  .hideMob {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .sectionTwo .imageWrapper {
    margin-left: 16px;
    width: calc(50% - 16px);
  }
  .sectionTwo .textWrapper {
    width: 50%;
  }
  .sectionTwo p {
    font-size: 36px;
    line-height: 50px;
  }

  .sectionSeven .testimonials {
    justify-content: space-around;
  }
  /* .sectionSeven .testimonials > div:last-of-type {
    display: none;
  } */
}

@media screen and (max-width: 750px) {
  .sectionTwo,
  .sectionThree,
  .sectionFour,
  .sectionFive,
  .sectionSix,
  .sectionSeven,
  .sectionEight {
    height: initial;
  }
  .inputContainer {
    width: 95%;
    height: 40px !important;
  }
  .sectionEight .sectionContent {
    padding: auto 26px;
  }
  .sectionOne h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: unset;
    letter-spacing: 0.2px;
  }
  .sectionOne p {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }
  .sectionOne {
    height: 220px;
  }
  .sectionTwo .sectionContent,
  .sectionThree .sectionContent,
  .sectionFour .sectionContent,
  .sectionFive .sectionContent,
  .sectionSix .sectionContent,
  .sectionSeven .sectionContent,
  .sectionEight .sectionContent {
    padding: var(--spacing-5) var(--spacing-4);
  }
  .sectionEight .textWrapper {
    width: 100%;
  }
  .sectionTwo .imageWrapper,
  .sectionThree .imageWrapper,
  .sectionFour .imageWrapper,
  .sectionFive .imageWrapper,
  .sectionSix .imageWrapper {
    margin: 0;
    display: flex;
    justify-content: center;
  }
  .imageWrapper5 {
    margin-left: 0;
  }
  .sectionTwo img,
  .sectionThree img,
  .sectionFour img,
  .sectionFive img,
  .sectionSix img {
    /* height: 630px;
    width: 300px; */
    height: 90vh;
    width: 100%;
  }
  .sectionTwo .textWrapper,
  .sectionThree .textWrapper,
  .sectionFour .textWrapper,
  .sectionFive .textWrapper,
  .sectionSix .textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* margin-top: var(--spacing-7); */
    margin-top: 18px;
  }

  .sectionTwo .sectionContent {
    flex-direction: column-reverse;
    padding: 10px 35px 70px 35px;
  }
  .sectionTwo .imageWrapper {
    background-position: center;
    width: 100%;
  }
  .inputContainer input {
    width: 60%;
    font-size: 12px;
  }
  .inputContainer button {
    width: 40%;
    font-size: 12px;
  }
  .sectionEight .inputContainer {
    width: 100%;
    background-color: #ffff;
  }
  .sectionTwo img {
    position: relative;
    left: 0;
    bottom: 0;
    transform: none;
  }
  .sectionTwo .textWrapper {
    width: 100%;
  }
  .sectionTwo p {
    text-align: center;
    margin-top: 0;
    margin-bottom: var(--spacing-1);
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
  }
  .sectionTwo p h3 {
    font-size: 18px;
  }
  .sectionEight p {
    text-align: center;
    margin-top: 0;
    margin-bottom: var(--spacing-1);
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  }
  .sectionTwo .textWrapper2 {
    font-size: 17px;
    line-height: 30px;
    margin-top: 12px;
  }
  .sectionThree h1,
  .sectionFive h1,
  .sectionSix h1,
  .sectionFour h1 {
    text-align: center;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    margin: 10px 0px;
  }
  .sectionThree p,
  .sectionFour p,
  .sectionFive p,
  .sectionSix p {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    margin: 10px 0px;
    width: 100%;
  }
  .sectionSeven .sectionContent h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 60px;
  }
  .sectionEight h1 {
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
  }
  .sectionThree .sectionContent,
  .sectionFive .sectionContent {
    flex-direction: column;
  }

  .sectionFour .sectionContent,
  .sectionSix .sectionContent {
    flex-direction: column-reverse;
  }

  .sectionSeven .testimonials {
    justify-content: center;
  }
  /* .sectionSeven .testimonials > div {
    display: none;
  } */
  /* .sectionSeven .testimonials > div:first-of-type {
    display: block;
  } */
  .sectionOne .sectionContent h2 {
    line-height: unset;
    margin-bottom: 0px;
    font-size: 18px;
  }
  .imageWrapper img {
    height: 100vh;
    width: 100%;
  }
  .bluebarDiv {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .sectionContent {
    padding: 30px 20px !important;
  }
  .sectionOne .sectionContent {
    height: 85%;
    padding: 10px 20px !important;
  }
  .inputDiv {
    width: 100%;
  }
  .hideMob {
    display: none;
  }
}

.wireframe1Splash {
  width: 367.99px;
  height: 711px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wireframe1contentDiv {
  overflow-x: hidden;
  height: 531px;
  overflow-y: auto;
  position: relative;
  width: 301px;
  position: absolute;
  top: 94px;
  z-index: 9;
}
.wireframe1contentDiv img {
  width: 100%;
  position: unset;
  bottom: unset;
  left: unset;
  transform: unset;
  /* width: unset; */
  object-fit: unset;
  height: unset;
  width: unset;
  top: unset;
}
.wireframe1contentDiv::-webkit-scrollbar {
  width: 0%;
  /* scrobar-tra */
}
.wireframe1mainDiv {
  display: flex;
  /* align-items: center; */
  height: 711px;
  width: 367.99px;
  justify-content: center;
  position: relative;
}
.wireframe1mainDiv img {
  height: 100%;
}
.wireframe1frame {
  position: absolute;
  z-index: 2;
}

.wireframe2Splash {
  width: 367.99px;
  height: 711px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}
.wireframe2contentDiv {
  overflow-x: hidden;
  height: 490px;
  overflow-y: auto;
  position: relative;
  width: 277px;
  position: absolute;
  top: 119px;
  z-index: 9;
}
.wireframe2contentDiv img {
  width: 100% !important;
  position: unset;
  bottom: unset;
  left: unset;
  transform: unset;
  /* width: unset; */
  object-fit: unset;
  height: unset;
  width: unset;
  top: unset;
}
.wireframe2contentDiv::-webkit-scrollbar {
  width: 0%;
  /* scrobar-tra */
}
.wireframe2mainDiv {
  display: flex;
  /* align-items: center; */
  height: 711px;
  width: 367.99px;
  justify-content: center;
  position: relative;
}
.wireframe2mainDiv img {
  height: 100%;
}
.wireframe2frame {
  position: absolute;
  z-index: 2;
}
.wireframe2Button1 {
  position: absolute;
  right: 1px;
  top: 206px;
  width: 85px;
  height: 26px;
  z-index: 15;
  border: none;
  background: transparent;
}
.wireframe2Button2 {
  position: absolute;
  right: 52px;
  top: 248px;
  width: 175px;
  height: 30px;
  z-index: 15;
  border: none;
  background: transparent;
}
.wireframe2Button3 {
  position: absolute;
  left: 16px;
  top: 84px;
  width: 16px;
  height: 18px;
  z-index: 15;
  border: none;
  background: transparent;
}
.wireframe2Button4 {
  position: absolute;
  right: 1px;
  top: 206px;
  width: 85px;
  height: 26px;
  z-index: 15;
  border: none;
  background: transparent;
}

.sectionTwo p span,
.sectionThree p span,
.sectionFour p span,
.sectionFive p span,
.sectionSix p span {
  padding-right: 10px;
}

.sectionEight .inputContainer {
  border-color: #42c0f5;
}
.EnterToWin {
  width: 100% !important;
  background-color: white !important;
  color: #42c0f5 !important;
}
.appLaunching {
  margin-bottom: auto;
}
.sectionTwo p {
  margin-bottom: 20px;
}
b {
  font-weight: bolder;
}
/* .sectionTwo .sectionContent p, */
.sectionThree .sectionContent p,
.sectionFour .sectionContent p,
.sectionFive .sectionContent p,
.sectionSix .sectionContent p
/* .sectionSeven .sectionContent p */
/* .sectionEight .sectionContent p */ {
  display: flex;
}
.sectionEight .lastSecButton {
  width: 100%;
}
.sectionTwo .sectionContent {
  padding: 30px 20px 80px 20px !important;
}

/* v2 */
.v2wireframe1Splash {
  width: 367.99px;
  height: 711px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v2wireframe1contentDiv {
  /* overflow-x: hidden;
  height: 538px;
  overflow-y: auto;
  position: relative;
  width: 302px;
  position: absolute;
  top: 90px;
  z-index: 9; */
  overflow-x: hidden;
  height: 310px;
  overflow-y: auto;
  position: relative;
  width: 301px;
  position: absolute;
  top: 314px;
  z-index: 9;
}
.v2wireframe1contentDiv img {
  width: 100%;
  position: unset;
  bottom: unset;
  left: unset;
  transform: unset;
  /* width: unset; */
  object-fit: unset;
  height: unset;
  width: unset;
  top: unset;
}
.v2wireframe1contentDiv::-webkit-scrollbar {
  width: 0%;
  /* scrobar-tra */
}
.v2wireframe1mainDiv {
  display: flex;
  /* align-items: center; */
  height: 711px;
  width: 367.99px;
  justify-content: center;
  position: relative;
}
.v2wireframe1mainDiv img {
  height: 100%;
}
.v2wireframe1frame {
  position: absolute;
  z-index: 2;
}
/* v2 end */

/* v2.1 */
.v2dot1 .EnterToWin {
  width: 100% !important;
  background-color: #42c0f5 !important;
  color: #fff !important;
}
.v2dot1 .sectionOne {
  height: unset;
  padding: 150px;
}
.v2dot1 .sectionTwo h1,
.v2dot1 .sectionTwo h2 {
  font-size: 28px;
  text-align: center;
}
.v2dot1 .sectionOne p {
  font-size: 38px;
  line-height: unset;
}

.v1c {
  .sectionOne {
    .sectionContent {
      padding: 30px 20px !important;
    }
    @media screen and (min-width: 601px) {
      .hideinDesktop {
        display: none;
      }
    }
    @media screen and (max-width: 600px) {
      height: unset;
      .hideInMobV1c {
        display: none;
      }
      .inputContainer {
        margin: 10px;
      }
      .EnterToWin {
        background-color: #42c0f5 !important;
        color: #ffff !important;
      }
    }
  }
  .sectionTwo {
    @media screen and (min-width: 601px) {
      .hideinDesktop {
        display: none;
      }
    }
    .inputContainer,
    .hideInMobV1c {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  }
}
.v2a {
  background-color: #f7f7fa;
  .splashMain {
    padding-top: 35px;
  }
  section {
    background: white;
    // height: auto;
    border-radius: 0px !important;
    padding: 30px 0px;
  }
  section + section {
    margin: 30px 0px;
  }
  .sectionTwo {
    background-color: #42c0f5 !important;
    border-radius: unset;
    color: white;
    .imageWrapper {
      background-image: unset;
      margin-top: unset;
    }
    .inputDiv {
      color: white;
      p {
        text-align: left !important;
        @media screen and (max-width: 600px) {
          text-align: center !important;
        }
      }
      h4 {
        margin: 15px 0px;
        font-size: 40px;
        font-weight: 600;
        @media screen and (max-width: 600px) {
          margin-bottom: 20px;
        }
      }
    }
    .EnterToWin {
      background-color: white !important;
      color: #42c0f5 !important;
    }
    p {
      font-size: 28px;
      line-height: 50px;
      font-weight: 600;
      @media screen and (max-width: 600px) {
        font-size: 16px;
      }
    }
    .sectionContentDiv {
      padding: 20px;
      .sectionContent {
        color: black;
        flex-direction: column;
        text-align: center;
        padding: 20px 20px 0px 20px !important;
        h2 {
          font-size: 26px;
        }
        @media screen and (max-width: 600px) {
          h1 {
            font-size: 20px;
            line-height: unset;
          }
          h2 {
            font-size: 15px;
          }
        }
      }
    }
    .sectionContent {
      justify-content: center;
      align-items: center;
    }
    .imageWrapper {
      margin-left: 180px;
      @media screen and (max-width: 768px) {
        margin-left: 0px;
      }
    }
  }
  .reviewImageDiv {
    width: 50%;
    text-align: center;
    width: 240px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .reviewImageDiv .image {
    border-radius: 50%;
    width: 50%;
    height: 100%;
    background-position: center top;
    background-size: cover;
  }
  .reviewSec {
    display: flex;
    align-items: center;
    justify-content: center;
    .reviewDiv {
      padding: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      min-width: 440px;
      max-width: 850px;
      @media screen and (max-width: 600px) {
        padding: 40px 40px;
      }
      p {
        font-weight: bold;
        line-height: 25px;
        text-align: left;
      }
    }
    .introBr {
      margin-top: 5px;
      text-align: center !important;
    }
  }
  .reviewDiv svg {
    color: #ff9320;
  }
  .startDiv {
    padding: 10px 0px;
  }
  .appDownload {
    .textRow1 {
      font-size: 10px;
    }
    li {
      cursor: pointer;
    }
  }
  .chamber {
    text-align: center;
    padding: 10px 0px 30px;
    p {
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0.13px;
      color: var(--gray-2);
    }
    img {
      max-width: 400px;
      margin: 10px 0px 20px;
    }
    .endrosedText {
      font-size: 26px;
      line-height: 32px;
      letter-spacing: 0.13px;
      color: var(--gray-2);
    }
    .endroseDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      .endroseLogoDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 600px) {
          flex-direction: column;
          img {
            margin: 0px;
            margin-bottom: 20px;
          }
        }
      }

      img {
        object-fit: scale-down;
        width: 160px;
        margin-right: 40px;
        &:last-child {
          margin: 0px;
        }
      }
    }
  }

  .sectionTwo {
    .AppDownloadDiv {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media screen and (max-width: 600px) {
        justify-content: center;
      }
    }
  }
  .DownloadNowHead {
    padding: 30px 0px;
    h3 {
      font-weight: bold;
    }
  }
  .sectionEight {
    .textWrapper {
      b {
        margin: 10px;
      }
    }
  }
  .AppDownloadDiv {
    margin: 10px 0px;
  }
}
.AppDownloadDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  .buttonDown {
    height: 49px !important;
    width: 174px;
    position: initial;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-bottom: 20px;
    z-index: 3;
  }
}
.vOrig {
  .sectionTwo .appLaunching,
  .sectionEight .appLaunching {
    padding: 20px 0px 0px 0px;
    font-size: 15px;
    margin: 0px !important;
  }

  .sectionTwo .sectionContent {
    padding: 50px 20px 50px 20px !important;
  }
  .inputContainer button {
    font-size: 28px;
  }
  .sectionEight .appLaunching {
    padding: 10px 0px;
    line-height: normal;
  }
  .socialMediaBtns {
    margin-top: 14px;
  }
  .sectionEight {
    padding: 10px 0px;
  }
  .AppDownloadDiv {
    margin: 20px 0px 30px;
  }
  .socialMediaBtns {
    margin-top: 0px;
  }

  @media screen and (max-width: 500px) {
  }
  @media screen and (min-width: 600px) {
    .hidePc {
      display: none;
    }
    .sectionTwo .appLaunching,
    .sectionEight.appLaunching {
      line-height: 23px;
    }
  }
}
@media screen and (max-width: 900px) {
  .v2dot1 .sectionOne {
    height: unset;
    padding: 150px 70px;
  }
}
@media screen and (max-width: 600px) {
  .v2dot1 .sectionOne {
    height: unset;
    padding: 30px 0px;
  }
  .v2dot1 .sectionTwo h1,
  .v2dot1 .sectionTwo h2 {
    font-size: 18px;
    text-align: center;
    line-height: 30px;
  }
  .v2dot1 .sectionOne p {
    font-size: 18px;
    line-height: unset;
  }
  .v2dot1 .sectionOne .inputContainer {
    margin: 20px 0 0;
  }
}
@font-face {
  font-family: Manrope;
  src: url("./../../assets/Manrope/Manrope-VariableFont_wght.ttf");
}
@font-face {
  font-family: ManropeBold;
  src: url("./../../assets/Manrope/static/Manrope-Bold.ttf");
}
.v4Main {
  font-family: "Manrope" !important;
  overflow: hidden;
  .v4Page {
    font-family: "Manrope" !important;
    padding: 153px 60px;
    background: white;
    position: relative;
    @media screen and (max-width: 600px) {
      padding: 20px 0px;
    }

    .v4CommingSoonSec {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 0px 140px;
      @media screen and (max-width: 600px) {
        margin: 0px 0px 80px;
      }
      .innerCommingSec {
        max-width: 1300px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 600px) {
          flex-direction: column-reverse;
        }
        .commingSoonText {
          width: calc(100% - 622px);
          display: flex;
          align-items: center;
          justify-content: center;
          @media screen and (max-width: 600px) {
            width: 100%;
          }
          h2 {
            font-family: "ManropeBold";

            font-style: normal;
            font-weight: 800;
            font-size: 72px;
            line-height: 98px;
            /* identical to box height, or 136% */

            font-feature-settings: "liga" off;

            /* Text / Gray 900 */

            color: #18191f;

            /* Inside Auto Layout */

            flex: none;
            order: 0;
            flex-grow: 0;

            @media screen and (max-width: 600px) {
              font-size: 24px;
              line-height: 32px;
              font-weight: 800;
              font-size: 24px;
              line-height: 27px;
              /* or 115% */

              text-align: center;
              font-feature-settings: "liga" off;

              color: #232934;
              margin: 0px;
            }
          }
          p {
            font-family: "Manrope";
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 32px;
            /* or 178% */

            font-feature-settings: "liga" off;

            /* Text / Gray 900 */

            color: #18191f;

            /* Inside Auto Layout */

            flex: none;
            order: 1;
            flex-grow: 0;
            margin: 16px 0px;
            margin-bottom: 32px;
            @media screen and (max-width: 600px) {
              font-size: 16px;
              margin: 16px 0 24px;
              text-align: center;
            }
          }
          .sendEmail {
            display: flex;
            align-items: center;
            @media screen and (max-width: 600px) {
              flex-direction: column;
            }
            input {
              opacity: 0.2;
              border: 2px solid #232934;
              box-sizing: border-box;
              border-radius: 100px;

              font-family: "Manrope";
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 26px;
              outline: none;
              /* identical to box height, or 130% */

              display: flex;
              align-items: center;

              color: #232934;

              opacity: 0.4;
              border-radius: 100px;
              padding: 16px 0px 16px 24px;
              margin-right: 10px;

              width: 350px;

              @media screen and (max-width: 600px) {
                margin: 0px 0px 10px;
              }
            }
            button {
              background: #42c0f5;
              border-radius: 100px;

              font-family: Poppins;
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 26px;
              /* identical to box height, or 130% */

              text-align: center;
              letter-spacing: -0.25px;

              color: #ffffff;
              padding: 16px 36px 18px 33px;
              outline: none;
              border: 0px;
            }
          }
        }
        .comingSoonImage {
          width: 622px;
          @media screen and (max-width: 600px) {
            width: 100%;
          }
          img {
            width: 100%;
            transform: translateZ(0);
          }
        }
      }
    }

    .v4HeadingDiv {
      text-align: center;
      position: relative;
      h2 {
        font-family: "ManropeBold";
        font-style: normal;
        font-weight: 800;
        font-size: 48px;
        line-height: 58px;
        text-align: center;
        font-feature-settings: "liga" off;
        color: #232934;
        z-index: 3;

        letter-spacing: 1px;
        margin-bottom: 0px;
        @media screen and (min-width: 600px) {
          .hidePc {
            display: none;
          }
        }
        @media screen and (max-width: 600px) {
          font-size: 24px;
          line-height: 32px;
          font-weight: 800;
          font-size: 24px;
          line-height: 27px;
          /* or 115% */

          text-align: center;
          font-feature-settings: "liga" off;

          color: #232934;
          margin: 0px;
        }
      }
      p {
        z-index: 3;
        font-family: "Manrope";
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;
        text-align: center;
        font-feature-settings: "liga" off;
        color: #232934;
        opacity: 0.8;
        margin: 24px 0 40px;
        @media screen and (max-width: 600px) {
          font-size: 16px;
          margin: 16px 0 24px;
        }
      }
      .AppDownloadDiv {
        .buttonDown:first-child {
          margin-right: 17.29px;
          @media screen and (max-width: 600px) {
            margin-bottom: 20px;
            margin-right: 0px;
          }
        }
        .buttonDown {
          width: 200px;
          height: 59px !important;
          margin-bottom: 0px;
        }
      }
      .hidePcDotPttern {
        position: absolute;
        left: -10px;
        width: 108px;
        bottom: -80px;
        display: none;
        @media screen and (max-width: 600px) {
          display: block;
        }
        svg {
          width: 100%;
        }
      }
    }
    .mobileMainReview {
      margin: 50px 0px;
      position: relative;
      display: none;
      @media screen and (max-width: 600px) {
        display: block;
      }

      @media screen and (max-width: 600px) {
        margin: 17px 0px;
      }
    }
    .reviewMobSlider {
      display: none;
      @media screen and (max-width: 600px) {
        display: block;
        .reviewSlide {
          margin: 21px;
          width: 90% !important;
          border-radius: 20px;
          box-shadow: 0px 0px 14px -10px black;
          padding: 20px;
          background-color: white;
        }
        .innerSlide {
          min-height: auto;
          padding: 0px;
          background: white;
          position: relative;

          p {
            font-family: "Manrope";
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 27px;
            /* or 169% */

            color: #3c3c3c;
          }
          .v4ReviewStars {
            text-align: center;
            margin-bottom: 20px;
            svg {
              left: 70.67%;
              right: 23.02%;
              top: 40.7%;
              bottom: 57.78%;

              transform: matrix(-1, 0, 0, 1, 0, 0);
            }
          }
          .innerSlide {
            min-height: 391px;
          }
        }
        .reviewrImage {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .reviewerDet {
            text-align: center;
            .reviewrName {
              margin: 16px 0px;
              font-size: 16px;

              font-style: normal;
              font-weight: 800;
              font-size: 16px;
              line-height: 19px;
              /* identical to box height, or 121% */

              color: #232934;
            }
            p {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 22px;
              /* or 157% */
              margin-bottom: 20px;
              text-align: center;

              color: #3c3c3c;
            }
          }
          .reviewrSteps {
            display: flex;
            align-items: center;
            left: c;
            justify-content: center;
            width: 88px;
            background: gainsboro;
            border-radius: 50%;
            height: 88px;
            text-align: center;
            img {
              width: 100%;
              border-radius: 50%;
              height: 100%;
            }
          }
        }
        .reviewSlide {
          display: flex !important;
          flex-direction: column-reverse;
        }
      }
      .slick-dots li button:before {
        font-size: 15px !important;
      }
    }
    .v4ReviewBox {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 48px;
      @media screen and (max-width: 600px) {
        display: none;
      }
      .v4innerBoxLeft,
      .v4innerBoxRight {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .v4innerBoxRight {
        align-items: flex-start;
        position: relative;
        .dotPttern {
          position: absolute;
          left: -20px;
        }
      }
      .reviewImage {
        width: 76.09px;
        height: 76.09px;
        border-radius: 50%;
        margin-right: 19.67px;
        img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          background: darkgray;
        }
      }
      .reviewerDet {
        display: flex;
        align-items: center;
      }
    }
    .v4Reviews {
      width: 540px;
      padding: 30px;
      margin: 24px 15px;

      background: #ffffff;
      box-shadow: 0px 2px 18px rgba(3, 44, 87, 0.28);
      border-radius: 20px;
      .reviewName p {
        font-family: "Manrope";

        font-family: "Manrope";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        /* or 157% */

        color: #3c3c3c;
        // margin-bottom: 16px;
      }
      h5 {
        font-family: "ManropeBold";
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 19px;
        margin-bottom: 6px;
      }
    }
    .reviewText {
      width: 460px;
      font-family: "Manrope";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      /* or 200% */

      color: #3c3c3c;
      p {
        margin: 16px 0px 0px;
        font-weight: 400;
      }
    }
    .SplashScreen_reviewName p {
      margin: 0px;
    }
    .v4ReviewStars svg {
      color: #ffd166;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      width: 23px;
      height: 23px;
    }
    .v4innerBoxLeft {
      position: relative;
      .waterDrop {
        position: absolute;
        opacity: 0.5;
        right: 490px;
        width: 176.22px;
        height: 147px;
        top: -70px;
      }
    }
    .v4InnerReviews {
      z-index: 1;
    }
    .circleBlob {
      left: 0;
      position: absolute;
      width: 80px;
      top: 70%;
      transform: translate(0, -55%);
      @media screen and (max-width: 600px) {
        top: 90%;
        transform: translate(0, -70%);
        display: none;
      }
    }
    .circleBlobMob {
      display: none;
      left: 0;
      position: absolute;
      width: 80px;
      top: 50%;
      transform: translate(0, -55%);
      @media screen and (max-width: 600px) {
        top: 50%;
        transform: translate(0, -50%);
        display: block;
      }
    }

    .rectangleBlob {
      position: absolute;
      right: 0px;
      width: 70px;
      top: 53%;
      transform: translate(0, -20%);
      @media screen and (max-width: 600px) {
        top: 11%;
      }
    }
  }
  .logoDivMob {
    display: none;
    align-items: center;
    justify-content: center;
    padding: 140px 0px 138px;
    background: #ececec;
    position: relative;
    @media screen and (max-width: 600px) {
      display: flex;
      padding: 38px 20px;
      margin-top: 20px;
    }
    .innerLogoDiv {
      max-width: 1300px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cbcLogo {
        width: auto;
        height: 30px;
      }
      .nbcLogo {
        width: auto;
        height: 48px;
      }
      .djLogo {
        width: auto;
        height: 37px;
      }
      .foxLogo {
        width: auto;
        height: 27px;
      }
      .pbsLogo {
        width: auto;
        height: 37px;
      }
    }
    .innerLogoDivMob {
      display: none;
    }
    @media screen and (max-width: 600px) {
      .innerLogoDivMob {
        display: block;
        max-width: 1300px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .cbcLogo {
          width: auto;
          height: 21px;
        }
        .nbcLogo {
          width: auto;
          height: 37px;
        }
        .djLogo {
          width: auto;
          height: 37px;
        }
        .foxLogo {
          width: auto;
          height: 21px;
        }
        .pbsLogo {
          width: auto;
          height: 27px;
        }
      }
      .innerLogoDiv {
        display: none;
      }
    }
  }

  .logoDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0px;
    background: #ececec;
    position: relative;
    @media screen and (max-width: 600px) {
      padding: 38px 20px;
      margin-top: 20px;
      display: none;
    }
    .innerLogoDiv {
      max-width: 1300px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cbcLogo {
        width: auto;
        height: 30px;
      }
      .nbcLogo {
        width: auto;
        height: 48px;
      }
      .djLogo {
        width: auto;
        height: 37px;
      }
      .foxLogo {
        width: auto;
        height: 27px;
      }
      .pbsLogo {
        width: auto;
        height: 37px;
      }
    }
    .innerLogoDivMob {
      display: none;
    }
    @media screen and (max-width: 600px) {
      .innerLogoDivMob {
        display: block;
        max-width: 1300px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .cbcLogo {
          width: auto;
          height: 21px;
        }
        .nbcLogo {
          width: auto;
          height: 37px;
        }
        .djLogo {
          width: auto;
          height: 37px;
        }
        .foxLogo {
          width: auto;
          height: 21px;
        }
        .pbsLogo {
          width: auto;
          height: 27px;
        }
      }
      .innerLogoDiv {
        display: none;
      }
    }
  }
  .AppDownloadDiv {
    .buttonDown {
      background-size: cover !important;
    }
  }
}
.v5Main {
  background: #f3fcff;
  // padding-top: calc(112px - 27px);
  font-family: Poppins;
  padding-top: 120px;
  @media screen and (max-width: 600px) {
    // padding-top: calc(84px - 27px);
  }
}

  .v5MainInner {
}

    .v5Heading,
    .v5HeadingInvite {
      // margin-top: 60px;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-bottom: 90.55px;
  @media screen and (max-width: 600px) {
    padding-bottom: 50px;
  }
      &.v5HeadingInvite {
        padding: 30px 0px 0px;
      }
      h3 {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 72px;
        text-align: center;

        /* color Primary */

        color: #42c0f5;
        margin-bottom: 0px;
        line-height: 58px;
        padding-bottom: 10px;
        &.backgroundLineImage {
          background-image: url("./../../assets/Rectangle\ 20.png");
          background-position: center bottom;
          background-repeat: no-repeat;
          background-size: 92%;
        }
       
        @media screen and (min-width: 1600px) {
          background-size: 100%;
        }

        @media screen and (max-width: 600px) {
          font-family: Poppins !important;
          font-style: normal !important;
          font-weight: 700 !important;
          font-size: 20px !important;
          line-height: 32px !important;
          text-align: center !important;
          color: #42c0f5 !important;
          background-size: 100%;
          word-spacing: 2px;
          padding: 0px 25px;
          padding-bottom: 3px;
          &.inviteText {
            font-size: 4.4vw !important;
          }
        }
      }
      h5 {
        margin-top: 50px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        /* identical to box height */

        display: flex;
        align-items: center;
        margin-bottom: 0px;
        text-align: center;
        justify-content: center;
        letter-spacing: 0.05em;

        font-family: Open Sans !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 24px !important;
        line-height: 32px !important;
        display: flex !important;
        align-items: center !important;
        text-align: center !important;
        letter-spacing: -0.01em !important;
        color: #434343 !important;
        margin-top: 35px !important;

        @media screen and (max-width: 600px) {
          font-family: Open Sans !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          line-height: 19px !important;
          display: flex !important;
          align-items: center !important;
          text-align: center !important;
          letter-spacing: -0.01em !important;
          color: #434343 !important;
          margin-top: 25px !important;
        }
      }
      h6 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        /* identical to box height, or 125% */

        text-align: center;

        /* color Primary */

        color: #42c0f5;
        margin: 0px;
        margin-top: 80px;
        @media screen and (max-width: 600px) {
          font-size: 20px !important;
        }
      }
      .free100{
        margin-top: 25px;
      }
      .AppDownloadDiv {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 28.13px;
        .buttonImage {
          // width: 201px;
          // height: 65px;
          cursor: pointer;
          display: flex;
          flex-grow: 1;
          &:first-child {
            margin-right: 21.71px;
            @media screen and (max-width: 600px) {
              margin: 0px;
              margin-bottom: 21px;
            }
          }
        }
        .buttonImage {
          border-radius: 8px;
          background-size: cover;
          @media screen and (max-width: 600px) {
            // height: 53px !important;
            width: 141px;
          }
          &:first-child {
            margin-right: 21px;
            @media screen and (max-width: 600px) {
              margin: 0px;
              margin-bottom: 21px;
            }
          }
        }
      }
    }
    .v5HeadingInvite {
      h5 {
        margin-top: 0px !important;
      }
    }
    .v5Heading1 {
      // padding-bottom: 50.55px;
      h3 {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 72px;
        text-align: center;

        /* color Primary */

        color: #42c0f5;
        // margin-bottom: -60px;
        // background-image: url("./../../assets/Rectangle\ 20.png");
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 62%;
        @media screen and (min-width: 1600px) {
          background-size: 45%;
        }

        @media screen and (max-width: 600px) {
          font-family: Poppins;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 30px;
          text-align: center;

          /* color Primary */

          color: #42c0f5;
        }
      }
      h5 {
        // margin-top: 30px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 33px;
        /* identical to box height */

        display: flex;
        align-items: center;
        margin-bottom: 0px;
        text-align: center;
        justify-content: center;
        letter-spacing: 0.05em;
        @media screen and (max-width: 600px) {
          font-size: 18px;
          line-height: 25px;
          margin-top: 60px;
        }
      }
      .AppDownloadDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 28.13px;
        .buttonImage {
          // width: 201px;
          // height: 65px;
          cursor: pointer;
          display: flex;
          flex-grow: 1;
          &:first-child {
            margin-right: 21.71px;
            @media screen and (max-width: 600px) {
              margin: 0px;
              margin-bottom: 21px;
            }
          }
        }
        .buttonImage {
          border-radius: 8px;
          background-size: cover;
          @media screen and (max-width: 600px) {
            // height: 53px !important;
            width: 141px;
          }
          &:first-child {
            margin-right: 21px;
            @media screen and (max-width: 600px) {
              margin: 0px;
              margin-bottom: 21px;
            }
          }
        }
      }
    }
    .v5Heading1 {
      padding-bottom: 40px;
      h3 {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: initial;
        text-align: center;

        /* color Primary */

        color: #42c0f5;
        // margin-bottom: -60px;
        // background-image: url("./../../assets/Rectangle\ 20.png");
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 62%;
        padding: 0px;
        @media screen and (min-width: 1600px) {
          background-size: 45%;
        }

        @media screen and (max-width: 600px) {
          font-family: Poppins;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 30px;
          text-align: center;

          /* color Primary */

          color: #42c0f5;
        }
      }
      h5 {
        // margin-top: 30px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 33px;
        /* identical to box height */

        display: flex;
        align-items: center;
        margin-bottom: 0px;
        text-align: center;
        justify-content: center;
        letter-spacing: 0.05em;
        @media screen and (max-width: 600px) {
          font-size: 18px;
          line-height: 25px;
          margin-top: 60px;
        }
      }
      .AppDownloadDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 28.13px;
        .buttonImage {
          // width: 201px;
          // height: 65px;
          cursor: pointer;
          display: flex;
          flex-grow: 1;
          &:first-child {
            margin-right: 21.71px;
            @media screen and (max-width: 600px) {
              margin: 0px;
              margin-bottom: 21px;
            }
          }
        }
        .buttonImage {
          border-radius: 8px;
          background-size: cover;
          @media screen and (max-width: 600px) {
            // height: 53px !important;
            width: 141px;
          }
          &:first-child {
            margin-right: 21px;
            @media screen and (max-width: 600px) {
              margin: 0px;
              margin-bottom: 21px;
            }
          }
        }
      }
    }
  
  .logoCon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ebebeb;
    padding: 48px;
    @media screen and (max-width: 600px) {
      padding: 33px 4px;
    }
    .innerLogoDivMob {
      display: flex;
      align-items: center;
      justify-content: space-around;
      max-width: 1300px;

      width: 100%;
      .nbcImage,
      .DJImage,
      .foxImage,
      .pbsImage,
      .cbcImage {
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #ebebeb;
        opacity: 0.6;
        transform: translateZ(0);
      }
      .nbcImage {
        height: 34.06px;
        @media screen and (max-width: 600px) {
          height: 28.07px;
        }
      }
      .DJImage {
        height: 34.06px;
        @media screen and (max-width: 600px) {
          height: 22.07px;
          display: none;
        }
      }
      .foxImage {
        height: 34.06px;
        @media screen and (max-width: 600px) {
          height: 22.07px;
        }
      }
      .pbsImage {
        height: 38.06px;
        @media screen and (max-width: 600px) {
          height: 28.65px;
        }
      }
      .cbcImage {
        height: 28.06px;
        @media screen and (max-width: 600px) {
          height: 18.65px;
        }
      }
    }
  }
  .reviewSliderCon {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .reviewSlider {
      // display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      margin: 0 auto 40px;
      max-width: 1300px;
      .reviewStars {
        font-size: 23.9px;
        color: #42c0f5;
        margin: 10px;
      }
      .reviewSlide {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        padding: 25px;
        border-radius: 10px;
        box-shadow: 0px 3px 15px 0px #00000026;
        margin: 30px 0px;
        background: white;
        height: 100%;
        .revieImage {
          width: 112px;
          height: 112px;
          margin-top: 30px;

          img {
            width: 100%;
          }
        }
        .reviewText {
          font-family: Poppins;
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          /* or 21px */

          text-align: center;

          /* grey */

          // color: #434343;
          color: #000;
        }
      }
    }
    //  .slick-slide {
    // }
    .reviewBox {
      padding: 10px;
      height: 550px;
      margin: 0px 0px 50px 0px;
      // margin: 10px;
    }
    &.secondReviewSliderCon{
      @media screen and (max-width: 600px) {
        
        .reviewBox{
          height: 680px;
        }
      }
    }
  }
  .reviewerDetail {
    font-size: 14px;
    h3,
    p {
      font-size: 14px;
    }
  }
.reviewerLogo {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #ebebeb;
  padding: 60px 0px;
  margin-top: 59px;
  .innerReviewLogo {
    max-width: 1300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    img {
      transform: translateZ(0);
    }
    .chmaberImage {
      max-height: 50.09px;
    }
    .pgiImage {
      max-height: 66px;
    }
    .johnImage {
      max-height: 66.6px;
    }
    .mvhImage {
      max-height: 69.78px;
    }
    @media screen and (max-width: 600px) {
      img {
        width: 30%;
      }
      .chamberDiv {
        width: 100%;
        margin-bottom: 21px;
        text-align: center;
        .chmaberImage {
          height: 37.25px;
          width: auto;
        }
      }
      .pgiImage {
        height: 52.3px;
        width: auto;
      }
      .johnImage {
        height: 50.26px;
        width: auto;
      }
      .mvhImage {
        height: 52.33px;
        width: auto;
      }
    }
    @media screen and (max-width: 340px) {
      img {
        width: 30%;
      }
      .chamberDiv {
        width: 100%;
        margin-bottom: 21px;
        text-align: center;
        .chmaberImage {
          height: 34.25px;
          width: auto;
        }
      }
      .pgiImage {
        height: 38.3px;
        width: auto;
      }
      .johnImage {
        height: 43.26px;
        width: auto;
      }
      .mvhImage {
        height: 45.33px;
        width: auto;
      }
    }
  }
}

.v7ComingSoon,
.v7ComingSoonFooter {
  background-image: none !important;
  color: black !important;
}
.v7ComingSoonFooter {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  margin-bottom: 36px;
}

.headerImage {
  border-radius: 50%;
  height: 180px;
  width: 180px;
  @media screen and (max-width: 600px) {
    width: 30vw;
    height: auto;
    aspect-ratio: 1;
  }
}
.imageHeader {
  text-align: center;
  margin-bottom: 80px;
}
.joingomo {
  text-align: center;
  margin: 80px 0px 140px;
}
.joingomobutton {
  border-radius: 50px;
  background: #42c0f5;
  color: white;
  border: 0;
  padding: 15px 35px;
  font-size: 18px;
  outline-offset: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  @media screen and (max-width: 600px) {
    padding: 15px 50px;
    font-size: 15px;
  }
}
.joingomobutton:focus {
  outline: 0;
}
.customh5 {
  margin-left: 3%;
  margin-right: 3%;
  font-size: 14px !important;
  // text-align: left !important;
  margin-top: 0px !important;
}

.inviteh5 {
  margin-left: 3%;
  margin-right: 3%;
  font-size: 14px !important;
  // text-align: left !important;
  margin-top: 0px !important;
}

.v7dot2 {
  .AppDownloadDiv {
    margin-top: 50px !important;
    @media screen and (max-width: 600px) {
      .buttonImage {
        margin-bottom: 10.38px !important;
      }
    }
  }
  .v7dot1FirstSec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1300px;
    margin: 0 auto;
    padding: 30px 40px;
    @media screen and (max-width: 600px) {
      flex-direction: column-reverse;
    }
  }
  .v5Main {
  padding-top: 184px;
  // padding-top: calc(45.44px - 27px) !important;
    @media screen and (max-width: 600px) {
      // padding-top: calc(36.68px - 27px) !important;
    }
  }
  .v5FirstSecImage {
    width: 50%;
    text-align: right;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
    img {
      background-size: contain;
      background-position: center;
      width: 80%;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      // height: 100%;
    }
  }
  // .v5Heading {
  //   // width: 50%;
  //   @media screen and (max-width: 600px){
  //   width: 100%;
  //   }
  //   // h3 {
  //   //   font-family: Poppins !important;
  //   //   font-style: normal !important;
  //   //   font-weight: bold !important;
  //   //   font-size: 48px !important;
  //   //   line-height: 58px !important;
  //   //   /* or 121% */

  //   //   text-align: left !important;

  //   //   @media screen and (max-width: 600px) {
  //   //     font-family: Poppins !important;
  //   //     font-style: normal !important;
  //   //     font-weight: bold !important;
  //   //     font-size: 30px !important;
  //   //     line-height: 40px !important;
  //   //     text-align: center !important;

  //   //     /* color Primary */

  //   //     color: #42c0f5 !important;
  //   //   }
  //   // }
  //   h5 {
  //     // font-family: Open Sans !important;
  //     // font-style: normal !important;
  //     // font-weight: 600 !important;
  //     // font-size: 24px !important;
  //     // line-height: 32px !important;

  //     // display: flex !important;
  //     // align-items: center !important;
  //     // text-align: left !important;
  //     // letter-spacing: -0.01em !important;

  //     // color: #434343 !important;
  //     // margin-top: 35px !important;
  //     // justify-content: flex-start !important;
  //     @media screen and (max-width: 600px) {
  //       font-family: Open Sans!important;
  //       font-style: normal!important;
  //       font-weight: 600!important;
  //       font-size: 14px!important;
  //       line-height: 19px!important;
  //       display: flex!important;
  //       align-items: center!important;
  //       text-align: center!important;
  //       letter-spacing: -.01em!important;
  //       color: #434343!important;
  //       margin-top: 30.8px!important;
  //     }
  //   }
  // }
}
