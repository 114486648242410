.main {
  width: 100%;
  background-color: white;
  padding: 24px 48px;
  box-sizing: border-box;
}
.main h1, .main h2, .main h3, .main h4, .main h5, .main p {
  padding-top: 15px;
  padding-bottom: 8px;
}
.mainH1{
  padding: 0 !important;
}
.PrivacyMainDiv p,.PrivacyMainDiv h1,.PrivacyMainDiv h2{
  margin-top: 0;
  margin-bottom: 1rem;
}
.mainH2{
  padding: 0 !important;
  text-decoration: underline;
}
.main h1 {
  font-size: 35px;
}
.main h2 {
  font-size: 30px;
}
.main h3 {
  font-size: 25px;
}
.main h4 {
  padding-top: 8px;
  font-size: 20px;
}
.main h5 {
  padding-top: 8px;
  font-size: 16px;
  font-weight: 700;
}
.main p {
  font-size: 16px;
  line-height: 25px;
  /* padding: 0 !important; */
}
.mainP{
  padding: 0 !important;
  font-weight: bold;
}
.mainp2{
  padding-top: 0 !important;
}
.mainP3{
  /* line-height: 20px; */
  font-size: 15px !important;
}
.mainP4{
  padding-top: 0px !important;
}
.mainp5{
  padding:0 !important;
  margin: 0 !important;
}
.mainp6{
  /* margin: 0 !important; */
  padding: 0 !important;
  padding-bottom: 8px !important;
}
 .main li {
  font-size: 16px;
  line-height: 25px;
}
.main li {
  padding-bottom: 8px;
}
.main ul {
  list-style: disc inside;
  padding-left: 16px;
}
.main ul .noBullet {
  list-style: none;
  padding-left: 22px;
}
.main b {
  font-weight: 600;
}
.main i {
  font-style: italic;
}