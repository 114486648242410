@font-face {
  font-family: "Open Sans";
  src: url("./../../assets/fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "SFPRODISPLAYBOLD";
  src: url("./../../assets/fonts/SFPRODISPLAYBOLD.OTF");
}

.backgroundWhite {
  background-color: white;
}

.sectionFourth {
  padding: 0px 0px;
  // padding: 0px 80px;
  // padding-top: 135px;

  .wireframeText {
    max-width: 1300px;
    margin: 0 auto;
    padding: 54.33px 40px;
    display: flex;
    align-items: center;
    // min-height: 100vh;
    justify-content: space-between;
    margin-bottom: 69px;

    // @media screen and (max-width: 600px) {
    // }
    @media screen and (max-width: 600px) {
      padding: 60px 20px 0px;
      flex-direction: column;
      margin-bottom: 60px;
      min-height: auto;

      // margin-top: 20px;
      &.colRev {
        flex-direction: column-reverse;
      }
    }

    div {
      // width: 50%;

      @media screen and (max-width: 600px) {
        width: 100% !important;

        &.imgWire {
          margin-bottom: 48px;
        }
      }

      img {
        height: 492px;
        text-align: left;
        transform: translateZ(0);

        @media screen and (max-width: 1024px) {
          width: 100%;
          object-fit: contain;
        }

        @media screen and (max-width: 600px) {
          height: auto;
          width: 100% !important;
          height: auto;
        }
      }

      .imgWire {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        @media screen and (max-width: 1024px) {
          width: 40%;
        }

        @media screen and (max-width: 600px) {
          margin-bottom: 33px;
        }
      }
    }

    &.colRev {
      @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
      }

      // text-align: right;
      .imgWire {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .textWire {
      padding: 0px;
      width: 50%;

      @media screen and (max-width: 942) {
        width: 40%;
      }

      @media screen and (max-width: 600px) {
        .hideMob {
          display: none;
        }
      }

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 54px;
        /* identical to box height */

        /* color Primary */

        margin: 0px;
        color: #42c0f5;

        @media screen and (max-width: 1024px) {
          font-size: 31px;
          line-height: 42px;
        }

        @media screen and (max-width: 600px) {
          font-size: 24px;
          line-height: 36px;
          text-align: center;
        }
      }

      p {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;

        /* grey */

        color: #434343;
        font-family: Poppins;
        margin: 0px;

        @media screen and (max-width: 1024px) {
          font-size: 15px;
        }

        @media screen and (max-width: 600px) {
          text-align: center;
          font-size: 14px;
          line-height: 25px;
          letter-spacing: 1px;
        }
      }
    }

    // .imgWire {
    //   text-align: center;
    // }
    &.wireframeText3 {
      .Image3 {
        width: 50%;

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }

      .textWire {
        width: 50%;
        padding: 0px;

        // padding-right: 100px;
        @media screen and (max-width: 600px) {
          padding: 0px;
          width: 100%;
        }
      }
    }

    .wireframeImage {
      width: auto;
      height: 495px;
      background-size: auto 100% !important;
      width: 100% !important;
      background-repeat: no-repeat;
    }
  }
}

.stripe {
  background: #ebebeb;

  // padding:  0px;
  // height: 250px;
  @media screen and (max-width: 600px) {
    height: auto;
    padding: 0px 10px;
  }

  .innerStripe {
    padding: 53px 40px;
    max-width: 1300px;
    position: relative;
    margin: 0 auto;

    .quoteS {
      position: absolute;
      top: 24px;
      left: 40px;

      @media screen and (max-width: 600px) {
        width: 35px;
      }
    }

    .quote {
      position: absolute;
      bottom: 24px;
      right: 40px;

      @media screen and (max-width: 600px) {
        width: 35px;
      }
    }

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      /* identical to box height, or 150% */

      text-align: center;

      color: #434343;
      margin-bottom: 60px;

      @media screen and (max-width: 600px) {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }

    .byName {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin: 0px;
      /* identical to box height, or 171% */

      text-transform: uppercase;

      color: #434343;
      text-align: center;
      position: relative;
      display: flex;

      // align-items: center;
      @media screen and (max-width: 600px) {
        font-size: 11px;
      }

      &::before {
        content: "";
        position: absolute;
        height: 1px;
        background: black;
        width: 15px;
        left: -25px;
        top: 11px;
      }
    }
  }
}

.imgWire {
  position: relative;
  width: 50%;

  .lottieDiv {
    width: 245px !important;
    height: 228px;
    position: absolute;
    left: 15%;
    top: 26.1%;
    background: #ffffff;
    border-radius: 10px;

    @media screen and (max-width: 600px) {
      width: 46% !important;
      height: auto;
      position: absolute;
      left: 2%;
      top: 25.9%;
      background-color: #ffffff;
      height: 47%;
    }
  }
}

.sectionFifth {
  // display: flex;
  // align-items: center;
  // justify-content: space-around;
  // padding: 0px 0px 135px 0px;
  background-image: url(./../../assets/backgroundElipse.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  &.hidePc {
    display: none;
  }

  @media screen and (max-width: 600px) {
    &.hideMob {
      display: none;
    }

    &.hidePc {
      display: block;
    }
  }

  .innerSection {
    // max-width: 1300px;
    // width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 20px 50px 20px;
    flex-direction: column;
    background: #ffffffd1;

    .slider {
      width: 100%;

      .slick-track {
        display: flex;
        align-items: center;
      }

      .innerSlider {
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100vh;
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;

        @media screen and (max-width: 600px) {
          height: 467px;
        }
      }

      .slide {
        height: 318px;
        position: absolute;
        transition: 0.3s;
        opacity: 0.3;

        @media screen and (max-width: 600px) {
          position: unset;
        }

        img {
          height: 100%;
          transform: translateZ(0);

          @media screen and (max-width: 600px) {
            width: 100%;
            height: auto;
          }
        }
      }

      .leftImage {
        left: 0;
        top: 50%;
        transform: translate(0%, -50%);
        transition: 0.3s;

        @media screen and (max-width: 600px) {
          display: none;
        }

        .lottieDiv {
          width: 25%;
          top: 36%;
          position: absolute;
          background: #e5f7ff;
          left: 10px;

          @media screen and (max-width: 600px) {
            width: 26%;
            top: 34%;
            position: absolute;
            background: #e5f7ff;
            left: 9px;
          }
        }
      }

      .rightImage {
        right: 0;
        top: 50%;
        transform: translate(0%, -50%);
        transition: 0.3s;

        @media screen and (max-width: 600px) {
          display: none;
        }
      }

      .leftImage.active {
        height: 70vh;
        opacity: 1;
        left: 40%;
        top: 50%;
        transform: translate(-40%, -50%);
        transition: 0.3s;

        @media screen and (max-width: 600px) {
          display: block;
          transform: none;
          height: auto;
          position: relative;
          left: 0px;
        }
      }

      .rightImage.active {
        height: 80vh;
        opacity: 1;
        left: 60%;
        top: 50%;
        transform: translate(-60%, -50%);
        transition: 0.3s;

        @media screen and (max-width: 600px) {
          transform: none;
          height: auto;
          display: block;
        }
      }

      .sliderNavs {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0px;

        div {
          margin: 0px 5px;
        }

        .navBtn {
          cursor: pointer;

          svg {
            fill: #e8e8e8;
          }

          &:hover {
            svg {
              border: 2px solid #42c0f5;
              border-radius: 50%;
            }
          }

          &.navActive {
            svg {
              fill: #42c0f5;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .hideMob {
      display: none;
    }
  }
}

.reviewSlider {
  // .slick-track {
  //   display: flex;
  //   height: 630px;
  // }
  // .slick-slide {
  //   opacity: 0.3;
  //   width: 400px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   .reviewSlide {
  //     width: 100%;
  //     height: 90%;
  //   }
  //   &.slick-current {
  //     width: 540px;
  //     opacity: 1;
  //     @media screen and (max-width: 600px) {
  //       min-width: 340px;
  //       width: min-content;
  //     }
  //     .reviewSlide {
  //       width: 100%;
  //       height: 100%;
  //       .reviewText {
  //         font-family: Poppins;
  //         font-style: normal;
  //         font-weight: 300;
  //         font-size: 16px;
  //         line-height: 178%;
  //         /* or 28px */

  //         display: flex;
  //         align-items: center;
  //         text-align: center;

  //         color: #000;
  //         @media screen and (max-width: 600px) {
  //           font-weight: 300;
  //           font-size: 12px;
  //           line-height: 178%;
  //         }
  //       }
  //     }
  //     .reviewBox {
  //       height: 620px;
  //     }
  //   }
  // }

  .slick-slide {
    // zoom: 90%;
    transform: scale(0.9);
    opacity: 1;
    opacity: 0.9;

    // width: 360px;
    @media screen and (max-width: 600px) {
      width: 82vw;
    }

    // .reviewSlide {
    //   div {
    //     opacity: 0.6;
    //   }
    // }

    &.slick-current {
      // zoom: 100%;
      transform: scale(1);
      opacity: 1;

      // width: calc(6% + 140px) !important;
      .reviewSlide {
        div {
          opacity: 1;
        }
      }
    }
  }
}

.v8-Slider {
  .reviewSlide {
    div {
      opacity: 1 !important;
    }
  }
}

.slick-dots li {
  margin: 0px;
}

.slick-dots li button:before {
  font-size: 14px;
}

.slick-dots li.slick-active button:hover {
  color: #42c0f5;
}

.slick-dots li.slick-active button:before {
  color: #42c0f5;
}

.reviewSlick {
  /* hiding all bullets by default */
  margin-top: 50px;

  &.slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: unset !important;
  }

  li {
    display: none;
  }

  /* only displaying the active bullets and the 2 bullets next to it */
  li.slick-active,
  li.slick-active+li,
  li.slick-active+li+li {
    display: block;
  }

  /* displaying the last three bullets when slick-active class isn't applied to any li before them  */
  li:nth-last-child(1),
  li:nth-last-child(2),
  li:nth-last-child(3) {
    display: block;
  }

  /* hiding the last three bullets if slick-active exist before them */
  li.slick-active~li:nth-last-child(1),
  li.slick-active~li:nth-last-child(2),
  li.slick-active~li:nth-last-child(3) {
    display: none;
  }

  /* specific conditions to always display the last three bullets */
  li.slick-active+li+li:nth-last-child(3),
  li.slick-active+li+li:nth-last-child(2),
  li.slick-active+li+li:nth-last-child(1),
  li.slick-active+li:nth-last-child(3),
  li.slick-active+li:nth-last-child(2),
  li.slick-active+li:nth-last-child(1) {
    display: block;
  }
}

.reviewSliderCon {

  // display: flex;
  // align-items: center;
  // justify-content: center;
  .reviewSlider {
    // display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0 auto 86.7px;
    max-width: 1300px;

    @media screen and (max-width: 600px) {
      margin-bottom: 50px;
    }

    .reviewStars {
      font-size: 23.9px;
      color: #42c0f5;
      margin: 10px;
    }

    .reviewSlide {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 25px 24px;
      border-radius: 10px;
      box-shadow: 0px 3px 15px 0px #00000026;
      margin: 30px 0px;
      background: white;
      height: 100%;

      @media screen and (max-width: 600px) {
        padding: 20px 24px !important;
      }

      .revieImage {
        width: 112px;
        height: 112px;
        margin-top: 30px;
        cursor: pointer;

        img {
          width: 100%;
        }
      }

      .reviewText {
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 178%;
        /* or 28px */

        display: flex;
        align-items: center;
        text-align: center;

        color: #000;
      }
    }

    // @media screen and (max-width: 600px) {
    //   .slick-list {
    //     padding: 0px !important;
    //     .slick-slide {
    //       width: auto !important;
    //       max-width: 360px;
    //     }
    //   }
    // }
  }

  //  .slick-slide {
  // }
  .reviewBox {
    padding: 10px;
    height: 590px;
    margin: 0px 0px 50px 0px;
    // @media screen and (max-width: 768px) {
    //   width: 434px !important;
    // }

    @media screen and (max-width: 600px) {
      // height: 510px;
      padding: 0px;
    }

    // margin: 10px;
  }

  .reviewerDetail {
    font-size: 14px;

    h3 {
      cursor: pointer;
    }

    h3,
    p {
      font-size: 14px;
      font-family: Poppins;
      line-height: 21px;
      text-align: center;
    }

    h3 {
      font-weight: bold;
      font-style: normal;
    }

    // @media screen and (max-width: 600px) {
    //   h3,
    //   p {
    //     font-size: 14px;
    //   }
    // }
  }

  .heartBobDiv {
    border-top: 1px solid #ebebeb;

    .heartBob {
      margin-bottom: 15px;
      margin-top: 15px;
    }

    p {
      margin: 0px;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;

      color: #434343;
    }
  }

  &.secondReviewSliderCon {
    @media screen and (max-width: 600px) {
      .reviewBox {
        height: 680px;
      }
    }
  }
}

.uniqueAppSec {
  // display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: 119px;

  @media screen and (max-width: 600px) {
    margin-top: 50px;
  }

  .innerUniqueSec {
    width: 100%;
    text-align: center;
    // display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 600px) {
      .uniqueText {
        padding: 0px 5px;
      }
    }

    .uniqueAppSlider {
      position: relative;
      margin: 6px 0px 50px;

      @media screen and (max-width: 942px) {

        // height: 666px;
        .slick-slider {
          height: 100%;
        }

        .slick-list {
          height: 100%;

          .slick-track {
            height: 100%;
            align-items: center;
            display: flex;
            // margin-top: 60px;
          }
        }
      }

      .slick-dots {
        // bottom: -50px;
      }

      // .slick-slide{
      //   outline: 0;

      //   transition: transform 0.5s;
      //   &.slick-current.slick-active{
      //     transform: none;
      //   }
      //   &.slick-active{
      //     transform: translateX(-50%);
      //   }
      //   &.slick-current + .slick-active{
      //     transform: translateX(50%);
      //   }
      // }
    }

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 54px;
      /* identical to box height */

      text-align: center;

      /* color Primary */

      color: #42c0f5;

      @media screen and (max-width: 942px) {
        font-size: 31px;
        line-height: 42px;
      }

      @media screen and (max-width: 600px) {
        font-size: 23px;
        line-height: 33px;
      }
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;
      text-align: center;

      color: #585858;

      // height: 54px;
      @media screen and (max-width: 600px) {
        // height: 84px;
        margin: 0px;
        font-style: normal;
        font-weight: 300;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        color: #585858;
      }
    }

    .wireframeDiv {

      // width: ;
      .wireframe {
        // height: 100vh;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        @media screen and (max-width: 942px) {
          height: 644px;
          width: 243px;
          margin: 0px 20px 10px;
        }

        .backMask {
          // width: 100%;
          // height: 100%;
          margin: 40px 36px 40px;
          // z-index: 1;
          width: 242.35px;
          // transform: translateZ(0);
          // filter: drop-shadow(0px 0px 9.30231px rgba(0, 0, 0, 0.3));
          box-shadow: 0px 0px 20px -0.69769px rgba(0, 0, 0, 0.3);

          @media screen and (min-width: 1500px) {
            width: 283.55px;
          }

          @media screen and (max-width: 942px) {
            margin: 16px 47px 47px;
            width: 234.35px;
            // height: 96%;
            // width: auto !important;
          }

          // opacity: 0.4 !important;
        }

        .maskImage {
          position: absolute;
          z-index: 2;

          width: 265px !important;
          top: 13px;

          @media screen and (min-width: 942px) {
            display: none;
          }

          @media screen and (max-width: 942px) {
            // width: 276px !important;
            display: block;
          }
        }
      }
    }

    .mobFrame {
      width: 273px;
      position: absolute;
      top: 50%;
      left: calc(50% - -2px);
      transform: translate(-50%, -50%);

      @media screen and (min-width: 1500px) {
        top: calc(50% + 2px);
        width: 322px;
        left: calc(50% + -5px);
      }

      @media screen and (max-width: 942px) {
        top: 3.5%;
        left: calc(50% - -2px);
        transform: translate(-50%, -3.5%);
      }

      @media screen and (max-width: 942px) {
        height: 596px;
        width: 263px;
        display: none;
      }

      svg,
      img {
        width: 100%;

        @media screen and (max-width: 600px) {
          // height: 70vh;
        }
      }
    }
  }

  .innerUniqueSec {

    // .slick-track {
    //   display: flex;
    //   align-items: center;
    // }
    // .slick-slide {
    //   opacity: 0.3;
    //   .reviewSlide {
    //     width: 100%;
    //     height: 90%;
    //   }
    //   &.slick-current {
    //     opacity: 1;
    //     .reviewSlide {
    //       width: 100%;
    //       height: 100%;
    //     }
    //     .wireframe {
    //       height: 100vh;
    //     }
    //   }
    // }
    .slick-dots li {
      margin: 0px;
    }

    .slick-dots li button:before {
      font-size: 14px;
    }

    .slick-dots li.slick-active button:hover {
      color: #42c0f5;
    }

    .slick-dots li.slick-active button:before {
      color: #42c0f5;
    }

    .slick-slider {
      .slick-slide {
        display: block;
        transform: scale(0.9);
        opacity: 0.4;
        // transition: .2s;
      }

      .slick-current {
        transform: scale(1);
        opacity: 1;

        // transition: .2s;
        img {
          // transform: translateZ(0);
        }
      }
    }
  }
}

.footerV5 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  padding-top: 50px;

  .innerFooter {
    display: flex;
    // align-items: center;
    justify-content: space-around;
    padding: 0px 40px;
    max-width: 1400px;
    width: 100%;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      width: 100%;
      padding: 0px 20px;
    }

    .downloadFooterBtn {
      width: 60%;

      @media screen and (max-width: 600px) {
        width: 100%;
      }

      .AppDownloadDiv {
        justify-content: flex-start;
        height: 70px;
        display: flex;

        @media screen and (max-width: 600px) {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 130px;
        }

        .buttonImage {
          background-repeat: no-repeat;
          // width: 206px;
          background-size: contain;
          // height: 60px;
          margin-right: 10px;
          cursor: pointer;
          display: flex;
          flex-grow: 1;

          @media screen and (max-width: 600px) {
            background-size: contain;
            // height: 80px;
            width: 141px;
            background-repeat: no-repeat;
            margin-bottom: 10.66px;
          }
        }
      }

      h2 {
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 54px;
        margin-bottom: 36px;

        /* color Primary */

        color: #42c0f5;

        @media screen and (max-width: 600px) {
          font-size: 20px;
          line-height: 193%;
          /* or 39px */

          text-align: center;
        }
      }
    }

    .tigerImgDiv {
      width: 30%;

      @media screen and (max-width: 600px) {
        width: 287.45px;
        height: 260.34px;
        margin: 30px auto 0px;
      }

      img {
        transform: translateZ(0);
        width: 100%;
      }
    }
  }

  .footerLinksMain {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #42c0f5;
  }

  .footerLinks {
    background: #42c0f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 29.5px 10px;
    max-width: 1400px;
    width: 100%;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    .socialLinks {
      display: flex;
      align-items: center;

      @media screen and (max-width: 600px) {
        margin-bottom: 10px;
      }

      .socialButton {
        background: white;
        width: 35px;
        height: 36px;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        border-radius: 50%;

        svg {
          width: 15px;
        }
      }
    }

    .footerCopyWrite {
      color: white;

      @media screen and (max-width: 600px) {
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 203.5%;
        /* or 20px */

        /* color White */

        color: #ffffff;
      }
    }

    .termsCoditionFooter {
      display: flex;

      @media screen and (max-width: 600px) {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        margin-top: 16px;
      }

      .termsLink {
        color: white;
        margin-right: 10px;
      }
    }
  }
}

.hidePc {
  display: none;
}

@media screen and (max-width: 600px) {
  .hideMob {
    display: none;
  }

  .hidePc {
    display: block;
  }
}

.appButtonsDiv {
  margin-top: 70px !important;
  margin-bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    margin-top: 50px !important;
    margin-bottom: 50px;
  }

  a {
    &:first-child {
      margin-right: 21px;

      @media screen and (max-width: 600px) {
        margin-right: 0px;
      }
    }
  }
}

.splashScreenV7Footer {
  h3 {
    color: #000;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    margin-top: 105px;
    color: #434343;

    // margin-bottom: 83px;
    .tapToFind {
      color: #42c0f5;
      cursor: pointer;
    }

    @media screen and (max-width: 600px) {
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }
  }

  h4 {
    color: #000;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;

    color: #434343;

    // margin-bottom: 83px;
    .tapToFind {
      color: #42c0f5;
    }

    @media screen and (max-width: 768px) {
      font-size: 31px;
      line-height: 42px;
    }

    @media screen and (max-width: 600px) {
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }
  }

  .ul {
    margin: 24px 0px 24px 20px;

    .li {
      font-family: Poppins;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;

      @media screen and (max-width: 600px) {
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 1px;
      }

      span {
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }

      /* grey */

      // color: #434343;
      // list-style: circle;
    }
  }

  li::before {
    content: "•";
    color: black;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  .AppDownloadDiv {
    margin-bottom: 123px;
  }
}

.downloadNow {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  margin-bottom: 36px;
  color: #42c0f5;
  text-align: center;
}

.v7-1AppDownload {
  justify-content: center;
  height: 70px;
  display: flex;
  margin-bottom: 123px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  img {
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    flex-grow: 1;
  }

  .loginPage .forgotPass {
    flex-direction: column;
  }

  .loginPage .formDiv .form {
    padding: 50px 20px 0px;
  }
}

.splashScreen7-2Fourth {
  .textWire {
    h2 {
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 54px;
    }

    p {
      font-family: Poppins;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;
    }
  }
}

.v7-2Heading {
  margin-bottom: calc(50px - 28.13px) !important;
}

.acceptCookiePop {
  position: fixed;
  z-index: 9999;
  position: fixed;
  z-index: 999999999999;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    background-color: #42c0f5;
    display: flex;
    background-color: #42c0f5;
    align-items: center;
    justify-content: space-between;
    padding: 26px 40px;
    border-radius: 10px;
    max-width: 826px;
    flex-wrap: wrap;
    // max-width: 876px;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      padding: 20px;
      width: calc(100% - 32px);
    }

    p {
      margin: 0px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      text-align: center;

      color: #ffffff;

      @media screen and (max-width: 600px) {
        margin-bottom: 16px;
      }
    }

    button {
      background: #ffffff;
      box-shadow: 0px 8px 24px rgba(66, 192, 245, 0.24);
      border-radius: 25px;
      padding: 8px 34px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      color: #42c0f5;
      outline: none;
      border: none;
      margin-right: 20px;
    }

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      text-align: center;
      text-decoration-line: underline;

      color: #ffffff;
    }
  }
}

.reminderSection {
  padding-top: 140px;
}

.reminder {
  // background-image: url("./../../assets/background.png");
  // padding-top: calc(99.25px + 75.74px);
  // background-position: top right;
  // background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    // padding-top: calc(28.25px + 75.74px);
  }

  .innerReminder {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    max-width: 1300px;
    width: 100%;

    @media screen and (max-width: 800px) {
      padding: 0px 30px;
    }

    @media screen and (max-width: 600px) {
      padding: 0px 0px;
    }

    .imageReminder {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 56px;
      position: absolute;
      top: 20px;
      z-index: 99999;
      // right: 16px;
      margin-bottom: 56px;

      img {
        height: 80px;
        border-radius: 1000px;
        object-fit: cover;
        aspect-ratio: 1;
      }

      @media screen and (max-width: 600px) {
        position: fixed;
        top: 20px;
        z-index: 99999;
        right: 16px;
        margin-bottom: 56px;
      }

      @media screen and (max-width: 600px) {
        img {
          width: 65px;
          height: 65px;
          object-fit: cover;
        }
      }
    }

    .reminderInfoDiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .reminderInfo {
      background-color: rgba(66, 192, 245, 0.1);
      padding: 24px;
      max-width: 700px;
      width: 100%;
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 80px;
      justify-content: center;

      @media screen and (max-width: 600px) {
        padding: 16px 12px;
        margin-bottom: 70px;
        border-radius: 14px;
        width: calc(100% - 48px);
      }

      .reminderCalender {
        font-family: "SF Pro Display";
        font-size: 22px;
        font-weight: 500;
        line-height: 36px;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 600px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .date {
          font-family: "SF Pro Display";
          font-size: 22px;
          font-weight: 500;
          line-height: 36px;
          letter-spacing: 0em;
          text-align: left;

          @media screen and (max-width: 600px) {
            font-family: SF Pro Display;
            font-size: 13px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: #434343;

            // letter-spacing: 1px;
          }
        }

        .addCalender {
          font-family: "SF Pro Display";
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;

          text-align: center;
          color: rgba(33, 155, 206, 1);

          @media screen and (max-width: 600px) {
            font-size: 14px;
            line-height: 25px;
            letter-spacing: 1px;
          }

          svg {
            margin-right: 12px;

            @media screen and (max-width: 600px) {
              width: 20px;
            }
          }
        }
      }

      .reminderContent {
        // padding: 40px 0 0;
        width: 100%;

        @media screen and (max-width: 600px) {
          // padding: 20px 0px 0px;
          width: 100%;
        }

        h3 {
          font-family: Poppins;
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;


          margin-bottom: 12px;

          @media screen and (max-width: 600px) {
            font-family: SF Pro Display;
            font-size: 18px;
            font-weight: 700;
            line-height: 21.48px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;


          }
        }

        p {
          font-family: SF Pro Display;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

          margin-bottom: 24px;
          color: #0F6A8C;


          @media screen and (max-width: 600px) {
            font-family: SF Pro Display;
            font-size: 16px;
            font-weight: 500;
            line-height: 22.4px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            margin-bottom: 20px;
          }
        }

        .imageVideoReminder {
          width: 100%;
          display: table;
          border-collapse: collapse;

          .innerImageVideoReminder {
            display: table-row;

            &.imageDivReminder {
              .image {
                padding-top: 18px;

                @media screen and (max-width: 600px) {
                  // padding-bottom: 18px;
                }
              }
            }

            .image {
              position: relative;
              aspect-ratio: 1;
              display: table-cell;



              // &:last-child {
              //   .imageDiv {
              //     display: flex;
              //     align-items: center;
              //     justify-content: flex-end;
              //   }
              // }

              .imageDiv {
                width: 100%;
                aspect-ratio: 1;
                position: relative;
                aspect-ratio: 2.59;

                @media screen and (max-width:600px) {
                  width: 100%;
                  aspect-ratio: 1.89;
                  // height: 169px;
                }

                .videoThumb {
                  width: 100%;
                  height: 100%;
                }

                .playIcon {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);

                  @media screen and (max-width:600px) {
                    width: 26px;
                  }
                }

                .lengthDiv {
                  position: absolute;
                  bottom: 0px;
                  width: 100%;
                  border-bottom-right-radius: 10px;
                  border-bottom-left-radius: 10px;

                  background: #00000057;

                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 8.36px 15px 6.29px 17px;


                  p {
                    color: white;
                    margin: 0px;

                    font-family: SF Pro Display;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 14.32px;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    text-align: right;
                  }

                  @media screen and (max-width:600px) {
                    width: 100%;
                    bottom: 0px;
                    padding: 0px 5px;
                    height: 26px;
                    right: auto;

                    svg {
                      width: 20px;
                      height: 20px;
                    }

                    p {
                      font-family: SF Pro Display;
                      font-size: 11px;
                      font-weight: 400;
                      line-height: 14px;
                      letter-spacing: 0.195193812251091px;
                      text-align: right;
                    }
                  }
                }

                img,
                video {
                  // height: calc(370px);
                  width: 100%;
                  aspect-ratio: 2.59;
                  background: white;
                  border-radius: 10px;
                  object-fit: cover;

                  @media screen and (max-width:600px) {
                    aspect-ratio: 1;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 10px;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
        }

        .audioReminder {
          margin-top: 18px;

          @media screen and (max-width:600px) {
            // .audioReminder {
            margin-top: 18px;
            // }
          }

          audio {
            width: 100%;

            @media screen and (max-width:600px) {
              margin-bottom: 40px;
            }
          }
        }

        .checkDone {
          font-family: "SF Pro Display";
          font-size: 32px;
          font-weight: 700;
          line-height: 38px;
          letter-spacing: 0em;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 90px;

          @media screen and (max-width: 600px) {
            margin-top: 0px;
            // font-size: 25px;
          }

          .form-group {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0px;

            @media screen and (max-width: 600px) {
              justify-content: space-around;
              margin-bottom: 0px;
            }

            .checkboxDiv {
              display: inline;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 72px;
              height: 72px;
              margin-right: 10px;

              @media screen and (max-width: 600px) {
                width: 35px;
                height: 35px;
                position: absolute;
                left: -45px;
                // vertical-align: text-bottom;
              }
            }

            input {
              padding: 0;
              height: initial;
              width: initial;
              margin-bottom: 0;
              display: none;
              cursor: pointer;
            }

            label {
              position: relative;
              cursor: pointer;
              width: 72px;
              height: 72px;
              display: flex;
              align-items: center;
              justify-content: center;
              // margin-right: 10px;
              margin-bottom: 0px;
              display: inline;

              @media screen and (max-width: 600px) {
                width: 35px;
                height: 35px;
                margin: 0px;
                // display: flex;
                // align-items: center;
              }
            }

            label:before {
              content: "";
              -webkit-appearance: none;
              background-color: transparent;
              border: 2px solid #27ae60;
              box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
                inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
              padding: 10px;
              display: inline-block;
              position: relative;
              vertical-align: middle;
              cursor: pointer;
              width: 72px;
              height: 72px;
              border-radius: 8px;
              padding: 0px;

              @media screen and (max-width:600px) {
                width: 35px;
                height: 35px;
                border-radius: 6px;
              }
            }

            input:checked+label:before {
              content: "";
              -webkit-appearance: none;
              background-color: #27ae60;
              border: 2px solid #27ae60;
              box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
                inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
              padding: 10px;
              display: inline-block;
              position: relative;
              vertical-align: middle;
              cursor: pointer;
              width: 72px;
              height: 72px;
              padding: 0px;

              @media screen and (max-width:600px) {
                width: 35px;
                height: 35px;
                border-radius: 6px;
              }
            }

            input:checked+label:after {
              content: "";
              display: block;
              position: absolute;
              width: 19px;
              height: 40px;
              border: solid #ffffff;
              border-width: 0 8px 9px 0;
              transform: rotate(45deg);
              top: 50%;
              left: 50%;
              transform: rotate(45deg) translate(-110%, -20%);

              @media screen and (max-width: 600px) {
                width: 9px;
                height: 18px;
                border-width: 0 4px 4px 0;
              }
            }

            p {
              font-family: Poppins;
              font-size: 32px;
              font-weight: 700;
              line-height: 48px;
              letter-spacing: 0em;
              text-align: center;
              margin-bottom: 0;
              display: flex;
              align-items: center;

              @media screen and (max-width: 600px) {
                font-family: Poppins;
                font-size: 15px;
                font-weight: 700;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: center;

                color: #434343;
                position: relative;
              }
            }
          }
        }

        .checkDoneContent {
          height: 0px;
          overflow: hidden;
          transition: 0.2s;

          &.active {
            margin-top: 34px;
            height: 268px;

            @media screen and (max-width: 600px) {
              min-height: auto;
              margin-top: 16px;
              height: 100%;
            }
          }

          .innercheckDoneContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            svg {
              @media screen and (max-width: 600px) {
                width: 70px;
              }
            }

            .greatJob {
              margin-top: 40px;
              margin-bottom: 0px;
              text-align: center;

              @media screen and (max-width: 600px) {
                margin-top: 12px;
              }

              h2 {
                font-family: Poppins;
                font-size: 32px;
                font-weight: 700;
                line-height: 48px;
                letter-spacing: 0em;
                text-align: center;

                @media screen and (max-width: 600px) {
                  font-family: Poppins;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 24px;
                  letter-spacing: 0em;
                  text-align: center;


                  margin-bottom: 0px;
                }
              }

              p {
                font-family: SF Pro Display;
                font-size: 24px;
                font-weight: 500;
                line-height: 36px;
                letter-spacing: 0em;
                text-align: center;
                margin: 0px;

                color: #434343;

                @media screen and (max-width: 600px) {
                  font-family: SF Pro Display;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 18px;
                  letter-spacing: 0em;
                  text-align: center;
                }
              }
            }

            .lottieDivParen {
              width: 100px;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              @media screen and (max-width: 600px) {
                width: 60px;
                height: 60px;
              }

              .lottieDiv {
                width: 160px;
                position: absolute;

                @media screen and (max-width: 600px) {
                  width: 80px;
                  position: absolute;
                }
              }
            }
          }
        }

        // .checkDoneContent {
        //   height: 0px;
        //   overflow: hidden;
        //   transition: 0.2s;

        //   &.active {
        //     margin-top: 34px;
        //     height: 268px;

        //   //   &.active {
        //   //     margin-top: 34px;
        //   //     width: 100%;
        //   //     height: auto;
        //   //     aspect-ratio: 3.51;
        //   //     background-color: #26434F;
        //   //     width: 652px;
        //   //     aspect-ratio: 3.51;
        //   //     padding: 24px;
        //   //     gap: 24px;
        //   //     border-radius: 16px;
        //   //     opacity: 0px;
        //   //     media screen and (max-width: 600px) {
        //   //     min-height: auto;
        //   //     margin-top: 16px;
        //   //     width: 100%;
        //   //     aspect-ratio: 1.61;
        //   //   }
        //   // }
        //   }

        //   .innercheckDoneContent {
        //     display: flex;
        //     flex-direction: column;
        //     align-items: center;
        //     justify-content: center;

        //     svg {
        //       @media screen and (max-width: 600px) {
        //         width: 70px;
        //       }
        //     }

        //     .greatJob {
        //       margin-top: 24px;
        //       margin-bottom: 0px;
        //       text-align: center;

        //       @media screen and (max-width: 600px) {
        //         margin-top: 16px;
        //       }

        //       h2 {
        //         font-family: Poppins;
        //         font-size: 24px;
        //         font-weight: 700;
        //         line-height: 36px;
        //         text-align: center;
        //         text-underline-position: from-font;
        //         text-decoration-skip-ink: none;
        //         text-align: center;
        //         color: #FFFFFF;

        //         @media screen and (max-width: 600px) {
        //           font-family: Poppins;
        //           font-size: 16px;
        //           font-weight: 700;
        //           line-height: 24px;
        //           text-align: center;
        //           text-underline-position: from-font;
        //           text-decoration-skip-ink: none;

        //           text-align: center;


        //           margin-bottom: 2px;
        //         }
        //       }

        //       p {
        //         font-family: Poppins;
        //         font-size: 16px;
        //         font-weight: 400;
        //         line-height: 24px;
        //         text-align: center;
        //         text-underline-position: from-font;
        //         text-decoration-skip-ink: none;
        //         text-align: center;
        //         color: #DEDEDE;
        //         margin: 0px;

        //         @media screen and (max-width: 600px) {
        //           font-family: Poppins;
        //           font-size: 14px;
        //           font-weight: 400;
        //           line-height: 22px;
        //           text-align: center;
        //           text-underline-position: from-font;
        //           text-decoration-skip-ink: none;
        //           text-align: center;
        //         }
        //       }
        //     }

        //     .lottieDivParen {
        //       display: flex;
        //       align-items: center;
        //       justify-content: center;
        //       position: relative;
        //       overflow: hidden;

        //       @media screen and (max-width: 600px) {
        //         width: 60px;
        //         height: 60px;
        //       }
        //       .successRound{
        //         width: 62px;
        //         height: 62px;
        //       }
        //       .successThumb{
        //         width: 30px;
        //         height: 30px;
        //             bottom: -50px;
        //             position: absolute;
        //         animation: thumbAnimation 1s forwards;
        //         animation-delay: 1.3s;
        //         overflow: hidden;
        //         @keyframes thumbAnimation {
        //           0%{
        //             bottom: -50px;
        //           }
        //           100%{
        //             bottom: 17px;
        //           }
        //         }
        //       }
        //       .lottieDiv {
        //         width: 160px;
        //         position: absolute;

        //         @media screen and (max-width: 600px) {
        //           width: 80px;
        //           position: absolute;
        //         }
        //       }
        //     }
        //   }
        // }
      }

      .reminderTopic {
        width: 90%;

        @media screen and (max-width: 800px) {
          width: 100%;
        }

        p {
          font-family: "SF Pro Display";
          font-size: 24px;
          font-weight: 500;
          line-height: 29px;

          text-align: center;
          color: rgba(45, 165, 216, 1);
          margin-bottom: 40px;

          @media screen and (max-width: 600px) {
            font-size: 14px;
            line-height: 25px;
            letter-spacing: 1px;
          }
        }

        .buttonsReminderJoin {
          width: 100%;
          display: flex;
          justify-content: space-between;

          @media screen and (max-width: 800px) {
            flex-direction: column;
          }

          .joinGoalmogul {
            padding: 17px 46px 17px 46px;
            border-radius: 100px;
            background-color: rgba(66, 192, 245, 1);
            border: 0px;
            font-family: "SF Pro Display";
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;

            text-align: center;
            color: white;
            outline: none;
            letter-spacing: 1px;

            @media screen and (max-width: 800px) {
              width: 100%;
              margin-bottom: 20px;
            }

            @media screen and (max-width: 600px) {
              font-size: 14px;
              line-height: 25px;
              letter-spacing: 1px;
            }
          }

          .continueGoogle {
            padding: 17px 36px 17px 36px;
            border-radius: 100px;
            background-color: white;

            font-family: "SF Pro Display";
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0em;
            text-align: center;
            outline: none;
            border: none;
            letter-spacing: 1px;

            @media screen and (max-width: 800px) {
              width: 100%;
            }

            @media screen and (max-width: 600px) {
              font-size: 14px;
              line-height: 25px;
              letter-spacing: 1px;
            }

            svg {
              margin-right: 16px;
            }
          }
        }
      }
    }

    .signUpInstantDiv {
      width: 100%;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media screen and (max-width: 600px) {
        background-color: transparent;
        padding: 0px 24px;
      }
    }

    .signUpInstant {
      margin-top: 50px;
      margin-bottom: 110px;
      max-width: 780px;
      width: 100%;
      // padding-bottom: 94px;

      @media screen and (max-width: 600px) {
        margin-bottom: 50px;
      }

      .signUpInstantInner {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .AppDownloadDiv {
        // margin-bottom: 120px !important;
        text-align: center;

        @media screen and (max-width: 600px) {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        a {
          @media screen and (max-width: 600px) {
            width: 142px;
            height: 46px;

            img {
              width: 100%;
            }
          }

          &:first-child {
            @media screen and (max-width: 600px) {
              margin-right: 16px;
            }

            img {
              margin-right: 20px;

              @media screen and (max-width: 600px) {
                margin-right: 0px;
                margin-bottom: 20px;
              }
            }

            // margin-right: 21px;
          }
        }
      }

      .signUpInstantHeading {
        font-family: Poppins;
        font-size: 32px;
        font-weight: 700;
        line-height: 58px;
        text-align: center;
        color: rgba(66, 192, 245, 1);
        margin-bottom: 16px;

        @media screen and (max-width: 600px) {
          font-size: 23px;
          line-height: 33px;
        }
      }

      .tipBox {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 31px 20px;
        color: #434343;

        @media screen and (max-width: 600px) {
          padding: 31px 0px;
        }

        .svgBox {
          width: 56px;
          margin-right: 8px;

          @media screen and (max-width: 600px) {
            width: 36px;

            svg {
              width: 100%;
            }
          }
        }

        .tipText {
          width: calc(100% - 64px);

          p {
            font-family: Open Sans;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: -0.01em;
            text-align: left;
            margin: 0px;

            @media screen and (max-width: 600px) {
              font-size: 14px;
              line-height: 25px;
              letter-spacing: 1px;
            }
          }
        }
      }
    }

    .joinGoalMogul {
      width: 278px;
      height: 72px;
      border-radius: 100px;
      box-shadow: 0px 8px 24px 0px #42c0f53d;
      background: #42c0f5;

      font-family: "Open Sans";
      font-size: 24px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
      margin-top: 80px;
      border-color: #42c0f5;
      border: none;

      @media screen and (max-width: 600px) {
        width: 208px;
        height: 52px;

        font-family: Open Sans;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 40px;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .joinGoalMogul {
    width: 240px !important;
  }

  .tipText p {
    text-align: center !important;
  }
}

.reminderHeading {
  // padding-bottom: 50.55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 84px;
  margin-bottom: 120px;

  &.togetherReminder {
    margin-bottom: 0px;
  }

  @media screen and (max-width: 600px) {
    margin-top: 0px;
    margin-bottom: 50px;
  }

  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 72px;
    text-align: center;

    /* color Primary */

    color: #42c0f5;
    margin-bottom: 0px;
    line-height: 58px;
    padding-bottom: 10px;

    &.backgroundLineImage {
      background-image: url("./../../assets/Rectangle\ 20.png");
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: 92%;
    }

    @media screen and (min-width: 1600px) {
      background-size: 100%;
    }

    @media screen and (max-width: 600px) {
      font-family: Poppins !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 20px !important;
      line-height: 32px !important;
      text-align: center !important;
      color: #42c0f5 !important;
      background-size: 100%;
      word-spacing: 2px;
      padding: 0px 25px;
      padding-bottom: 3px;

      &.inviteText {
        font-size: 4.4vw !important;
      }
    }
  }

  h5 {
    margin-top: 50px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */

    display: flex;
    align-items: center;
    margin-bottom: 0px;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.05em;

    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    letter-spacing: -0.01em !important;
    color: #434343 !important;
    margin-top: 35px !important;

    @media screen and (max-width: 600px) {
      font-family: Open Sans !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 19px !important;
      display: flex !important;
      align-items: center !important;
      text-align: center !important;
      letter-spacing: -0.01em !important;
      color: #434343 !important;
      margin-top: 25px !important;
    }
  }

  h6 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 125% */

    text-align: center;

    /* color Primary */

    color: #42c0f5;
    margin: 0px;
    // margin-top: 80px;
    margin-top: 40px;

    @media screen and (max-width: 600px) {
      font-size: 20px !important;
    }
  }

  .free100 {
    margin-top: 25px;
  }

  .AppDownloadDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .AppDownloadDiv {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // margin-top: 80px;

    .buttonImage {
      // width: 201px;
      // height: 65px;
      cursor: pointer;
      display: flex;
      flex-grow: 1;

      &:first-child {
        // margin-right: 21.71px;

        @media screen and (max-width: 600px) {
          margin: 0px;
          margin-bottom: 21px;
        }
      }
    }

    .buttonImage {
      border-radius: 8px;
      background-size: cover;

      @media screen and (max-width: 600px) {
        // height: 53px !important;
        width: 141px;
      }

      &:first-child {
        margin-right: 21px;

        @media screen and (max-width: 600px) {
          margin: 0px;
          margin-bottom: 21px;
        }
      }
    }
  }
}

.reminderBoxParent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 157px 0px;

  background-color: #42c0f5;

  .reminderBox {
    padding: 52px;
    max-width: 465px;
    width: 100%;
    background-color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    border-radius: 5px;

    @media screen and (max-width: 600px) {
      padding: 52px 20px;
    }

    .author {
      margin-bottom: 24px;

      img {
        width: 64px;
        height: 64px;
        object-fit: cover;
      }
    }

    .remindTextHead {
      text-align: center;
      font-family: SFPRODISPLAYBOLD;
      font-size: 24px;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: 0px;
      text-align: center;
      margin-bottom: 24px;
    }

    .remindText {
      text-align: center;

      font-family: "SF Pro Display";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.20000000298023224px;
      text-align: center;
      margin-bottom: 34px;

      span {
        color: #42c0f5;
      }
    }

    .reminderButton {
      height: 51px;
      border-radius: 10px;
      width: 100%;
      background-color: #42c0f5;

      font-family: "SF Pro Display";
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: center;
      color: white;
      border: none;
      letter-spacing: 1px;
      outline: none;

      box-shadow: 0px 4px 4px 0px #0000001a;
      margin-bottom: 34px;
    }
  }
}

.abdul-hannan {
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.last-text {
  @media screen and (max-width: 600px) {
    padding: 0px 10px;
  }
}

.showModalVideo {
  width: 100%;
  height: 100%;

  .modal-content {
    position: relative;

    .cancelModalVideo {
      position: absolute;
      top: -15px;
      right: -14px;
      background: white;
      border-radius: 50px;
      cursor: pointer;

      svg {
        width: 30px;
        height: 30px;
        color: #42c0f5;
      }
    }

    video {
      width: 100%;
    }
  }
}

// changes css is here

.heading-css {
  font-family: Poppins;
  font-size: 39.6px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: center;
  color: #42c0f5;

  @media screen and (max-width: 600px) {
    font-size: 23px;
    margin: 0px;
  }
}

@media screen and (max-width: 609px) {
  .how-it-work-heading {
    padding-bottom: 50px;
  }
}

.howItWorksSec {
  padding-top: 74px;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 600px) {
    padding-top: 0px;
  }
}

.change-style {
  font-family: Poppins;
  font-size: 39.6px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: left;
  color: #42c0f5;
}

.para-style-changes {
  font-family: Poppins;
  font-size: 19.8px;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #434343;
  font-family: "Nunito Sans", sans-serif;

  @media screen and (max-width:600px) {
    text-align: center;
    color: #585858;
    font-weight: 300;
    font-size: 17px;
    line-height: 20px;
    font-family: "Nunito Sans", sans-serif;
    margin-top: 10px;
  }
}

img.image1 {
  width: 679.8px;
}

// img.image2{
//   width: 308px;
// }

.step_1 {
  margin-top: 35px;

  @media screen and (max-width: 600px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.step_2 {
  margin-top: 35px;

  @media screen and (max-width: 600px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.step_3 {
  margin-top: 35px;

  @media screen and (max-width: 600px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.step_4 {
  margin-top: 35px;

  @media screen and (max-width: 600px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.how-it-works-page {
  padding-top: 57px;

  @media screen and (max-width: 600px) {
    padding: 0px;
  }
}

.free-text {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #42c0f5;

  @media screen and (max-width: 600px) {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 1054px) {
  .howItWorksSec {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:nth-child(even) {
      flex-direction: column-reverse;
    }
  }

  .change-style {
    text-align: center;
  }

  .para-style-changes {
    text-align: center;
  }
}

// @media screen and (max-width: 1054px) {
//  .new-child-setting{
//   &:nth-child(odd){
//     flex-direction: column;
//   }
//  }
// }

@media screen and (max-width: 609px) {
  img.image1 {
    width: 100%;
  }

  .how-it-works-page.hidePc {
    .howItWorksSec {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:nth-child(odd) {
        flex-direction: column-reverse;
      }
    }

    .step-1-images {
      display: flex;
      // align-items: center;
      flex-direction: column;
      justify-content: center;
      max-width: 310px;
      width: 100%;
      position: relative;

      div:last-child {
        text-align: right;
      }
    }

    .step-1Image {
      div:first-child {
        position: relative;
        left: -25px;
      }

      div:last-child {
        position: relative;
        right: -38px;
      }
    }

    .step-2Image {
      div:first-child {
        position: relative;
        left: -28px;
      }

      div:last-child {
        position: relative;
        right: -26px;
      }
    }

    .step-3Image {
      div:first-child {
        position: relative;
        left: -28px;
      }

      div:last-child {
        position: relative;
        right: -26px;
      }
    }

    .step-4Image {
      div:first-child {
        position: relative;
        left: -28px;
      }

      div:last-child {
        position: relative;
        right: -34px;
      }
    }

    img.image1 {
      max-width: 300px;
      width: 100%;

      &:first-child {
        margin-bottom: 35px;
      }
    }
  }

  .change-style {
    font-size: 23px;
    line-height: 33px;
    text-align: center;
  }
}

@media screen and (min-width: 1600px) {
  .howItWorksSec {
    padding-top: 74px;
    display: flex;
    justify-content: space-around;
    max-width: 1300px;
    width: 100%;
  }

  .how-it-works-page {
    padding-top: 57px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .how-it-works-page.hideMob {
    display: flex;
  }

  img.image1 {
    width: 700px;
  }
}

.AppDownloadDiv.extra-css {
  // margin-bottom: 120px !important;
  text-align: center;

  @media screen and (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    @media screen and (max-width: 600px) {
      width: 142px;
      height: 46px;

      img {
        width: 100%;
      }
    }

    &:first-child {
      @media screen and (max-width: 600px) {
        margin-right: 16px;
      }

      img {
        margin-right: 20px;

        @media screen and (max-width: 600px) {
          margin-right: 0px;
          margin-bottom: 20px;
        }
      }

      // margin-right: 21px;
    }
  }
}

.imageFullViewOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 104000;
  width: 100vw;
  height: 100vh;
  background-color: #00000050;
  padding: 10px;
}

.imageFullViewDiv {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 770px;
  width: 100%;
  z-index: 104001;

  // height: 50vh;
  @media screen and (max-width:600px) {
    width: calc(100% - 40px);
  }

  .imageFullView {
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    width: 100%;
  }

  .closeButtonImageFull {
    color: white;
    font-size: 30px;
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  video {
    width: 100%;
    object-fit: cover;
    height: 100%;
    box-shadow: 0px 0px 5px 0px black;
  }
}

// .dives{
//   position: sticky;
//    bottom: 10px;
//    width: 100%; border: 1px solid red;
// }

.confirmation_section {
  display: flex;
  position: fixed;
  width: 100%;
  max-width: 537px;
  padding: 0px;
  // bottom: 0px;
  text-align: center;
  z-index: 1;
  bottom: 0px;
  background: #EBEBEB;
  padding: 32px;
  box-shadow: 0px 0px 21px 0px #0000001F;
  left: 50%;
  transform: translate(-50%, 0);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  @media screen and (max-width: 768px) {
    background: #EBEBEB;
    padding: 14px 20px;
    border-top: 1px solid #B8B8B8;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .remiderIcon {
    margin-right: 24px;

    @media screen and (max-width: 768px) {
      margin-right: 20px;
    }
  }

  .dives {



    p.mesg {

      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
      line-height: 25.2px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;


      margin-bottom: 16px;
      color: #171717;

      @media screen and (max-width:768px) {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 22.4px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

      }

    }

    p.mesg2 {

      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;



    }

    .checkboxes {
      font-size: 24px;
      line-height: 22px;

      @media screen and (max-width: 600px) {
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.no-scroll {
  overflow: hidden;
  height: 100vh;
  touch-action: none; /* Prevents iOS from scrolling */
}
.iphone-modal {
  .modal-content{
    margin-bottom: 100px; /* Adjust margin as needed */
  }
}
$color1: #ffffff;
$color2: #42c0f5;

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.left-side {
  margin-left: 10px;
}

// .inner-rado {
//   margin-left: 21.88px;
// }
// .radio {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   // margin: 0.5rem;
//   input[type="radio"] {
//     position: absolute;
//     opacity: 0;
//     + .radio-label {
//       &:before {
//         content: "";
//         background: $color1;
//         border-radius: 100%;
//         border: 3px solid $color2;
//         display: inline-block;
//         width: 1.4em;
//         height: 1.4em;
//         position: relative;
//         top: -0.2em;
//         margin-right: 4px;
//         vertical-align: top;
//         cursor: pointer;
//         text-align: center;
//         transition: all 250ms ease;
//       }
//     }
//     &:checked {
//       + .radio-label {
//         &:before {
//           background-color: $color2;
//           box-shadow: inset 0 0 0 4px $color1;
//         }
//       }
//     }
//     &:focus {
//       + .radio-label {
//         &:before {
//           outline: none;
//           border-color: $color2;
//         }
//       }
//     }
//     &:disabled {
//       + .radio-label {
//         &:before {
//           box-shadow: inset 0 0 0 4px $color1;
//           border-color: darken($color1, 25%);
//           background: darken($color1, 25%);
//         }
//       }
//     }
//     + .radio-label {
//       &:empty {
//         &:before {
//           margin-right: 0;
//         }
//       }
//     }
//   }
// }

.custom-radio {
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  position: relative;
}

.radio-inner {
  width: 10px;
  height: 10px;
  background-color: #000;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  /* Hide by default */
}

.checked .radio-inner {
  display: block;
  /* Display when checked */
}

// .modals{
.in-center-too {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;


  @media screen and (max-width:768px) {

    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #808080;
  }
}

.in-center {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  margin-bottom: 12px;
  margin-top: 32px;

  @media screen and (max-width: 768px) {

    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
    margin-bottom: 8px;
    margin-top: 24px;
  }

}

// }
//





.modal-backdrop {
  z-index: 999000;
}

.reconfirmationModal {
  z-index: 999001;

  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 490px;
    width: 100%;

    @media screen and (max-width: 768px) {
      margin: 0px;
      align-items: flex-end;
      max-width: unset;
      width: 100%;
    }
  }

  .modal-content {
    padding: 32px;
    width: 100%;
    background: #EBEBEB;
    border-radius: 20px;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 24px 16px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;

    }
  }

  .modal-dialog-centered {
    @media screen and (max-width: 768px) {
      min-height: 100vh;
    }
  }

  .tickImageDiv {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
      img {
        width: 62px;
        height: 62px;
      }
    }
  }

  .checks {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    @media screen and (max-width: 768px) {
      margin: 0px;
    }
  }

  button.off {
    width: 192px;
    height: 52px;
    padding: 14px 12px 14px 12px;
    gap: 10px;
    border-radius: 200px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    background: #42C0F5;
    box-shadow: 0px -4px 4px 0px #FFFFFF4D inset;
    border: none;


    @media screen and (max-width:768px) {
      width: calc(50% - 7px);


    }
  }

  button.on {
    width: 192px;
    height: 52px;
    padding: 14px 12px 14px 12px;
    gap: 10px;
    border-radius: 200px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: #EBF9FF;
    box-shadow: 0px -4px 4px 0px #42C0F533 inset;
    border: none;
    color: #42C0F5;
    margin-right: 14px;


    @media screen and (max-width: 768px) {

      width: calc(50% - 7px);

    }
  }

  label.dontShowAgain {
    font-family: SF Pro Display;
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }

  input.boxCheck {
    width: 26px;
    // height: 8px;/
  }

  .chek {
    text-align: center;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 12px;

    .innerCheck {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: fit-content;


      p {
        font-family: SF Pro Display;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 0px;
      }
    }
  }

  .checkboxDiv {
    display: inline;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    margin-right: 10px;
    left: -20px;
    position: absolute;


    @media screen and (max-width: 600px) {
      width: 15px;
      height: 15px;
      position: absolute;
      left: -20px;
      // top: 0px;
      // vertical-align: text-bottom;
    }
  }

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .checkBoxLabel {
    position: relative;
    cursor: pointer;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-right: 10px;
    margin-bottom: 0px;
    display: inline;
    position: absolute;
    // left: -20px;
    top: 0px;

    @media screen and (max-width: 600px) {
      width: 15px;
      height: 15px;
      position: absolute;
      // left: -20px;
      top: 0px;
      // display: flex;
      // align-items: center;
    }
  }

  .checkBoxLabel:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #42c0f5;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    width: 15px;
    height: 15px;
    border-radius: 8px;
    padding: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 3px;

    @media screen and (max-width:600px) {
      width: 15px;
      height: 15px;
      position: absolute;
      left: 0px;
      top: 0px;
      border-radius: 3px;
    }
  }

  input:checked+.checkBoxLabel:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #42c0f5;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    width: 15px;
    height: 15px;
    padding: 0px;
    position: absolute;
    // left: -20px;
    top: 0px;

    @media screen and (max-width:600px) {
      width: 15px;
      height: 15px;
      position: absolute;
      // left: -20px;
      top: 0px;
    }
  }

  input:checked+.checkBoxLabel:after {
    content: "";
    display: block;
    position: absolute;

    border: solid #42c0f5;
    border-width: 0 8px 9px 0;
    transform: rotate(45deg);
    top: 50%;
    left: 50%;
    transform: rotate(45deg) translate(-110%, -20%);

    width: 6px;
    height: 10px;
    border-width: 0 3px 3px 0;

    @media screen and (max-width: 600px) {
      width: 6px;
      height: 10px;
      border-width: 0 3px 3px 0;
    }
  }

  label:not(.checkBoxLabel) {
    margin-bottom: 0px;
    font-size: 16px;

    @media screen and (max-width:768px) {
      font-size: 14px;

    }
  }
}


.radio {
  display: flex;
  align-content: center;
  // justify-content: center;
}

.inner-rado {
  display: flex;
  align-content: center;
  justify-content: center;

  label {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #171717;


    @media screen and (max-width:768px) {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin-bottom: 0px;
    }

  }

  &:first-child {
    margin-right: 21.88px;
  }
}

.radioBtn {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 3px solid #636366;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  &:first-child {
    margin-right: 5px;
  }

  &.active {

    border: 2px solid #42c0f5;

    .radioInnerBtn {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background-color: #42c0f5;

    }
  }
}