.ExtraPages.reminderConfirmataion {
  width: 100vw;
  ::after,
  ::before {
    box-sizing: unset;
    // border: unset;
  }

  .App {
    font-family: "SF Pro Display";
    min-height: 100vh;
    background-color: #42c0f5;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    // font-size: bold;

    .app-header {
      background-color: #fff;
      width: 100%;
      padding: 16px 0;
    }
    .header-content {
      max-width: 1140px;
      // padding: 0 16px;
      padding-bottom: 4px;
      margin: auto;
    }
    .app-footer {
      background: #3d3c3c;
      padding: 30px 0;
    }
    .app-footer p {
      font-family: "SF Pro Display";
      margin: 0;
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      line-height: 12px;
      padding: 0 32px;
    }
    .reminder-content {
      width: 465px;
      height: 519px;
      padding: 52px;
      display: flex;
      align-items: center;
      // justify-content: center;
      flex-direction: column;
      font-family: "SF Pro Display";
      margin:30px auto !important;
      &.ReminderUnsubscribe-reminder-content{
        height: auto;
        width: 507px;
      }
      .heading {
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0px;
        text-align: center;
        width: 100%;
        max-width: 100%;
        margin-bottom: 16px;
        &.ReminderUnsubscribeHeading{
          margin: 0px;
          margin-bottom: 40px;

        }
      }
      .text {
        font-family: "SF Pro Display";
        font-size: 14px;
        // font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.20000000298023224px;
        text-align: center;
        color: #000000;
        margin-bottom: 16px;
        max-width: 100%;
      }
      .blue-text {
        color: #42c0f5;
        font-weight: 700;
      }
      img{
        margin-bottom: 10px;
      }
      .reminder-buttonLink{
        max-width: 100%;
        cursor: pointer;
        width: 100%;
        margin-top: 19px;
        margin-bottom: 30px;

      }
      .reminder-button {
        width: 100%;
        background-color: #42c0f5;
        color: #fff;
        border: 0px;
        outline: none;
        border-radius: 10px;
        height: 51px;
    
        cursor: pointer;
      }
      .reminder-button-unsub{
        max-width: 100%;
        width: 100%;
        background-color: #42c0f5;
        color: #fff;
        border: 0px;
        outline: none;
        border-radius: 10px;
        margin-top: 19px;
        margin-bottom: 30px;
        padding: 16px 24px;

        font-family: 'SF Pro Display';
font-size: 16px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0px;
text-align: center;

text-transform: capitalize;
letter-spacing: 1.2px;
margin-bottom: 0px;

      }
      
    }
    .WeeklyUpdates-content {
      width: 357px;
      height: 305px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media screen and (max-width: 480px) {
      .reminder-content {
        width: 88%;
        height: 519px;
        padding: 12px;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-family: "SF Pro Display";
        &.ReminderUnsubscribe-reminder-content{
          width: 88%;
          height: auto;
        }
      }
    }
    @media screen and (max-width: 600px) {
      .WeeklyUpdates-content {
        width: 280px;
      }
      .Mail-content {
        width: 280px;
      }
      .header-content {
        margin-left: 16px;
      }
    }
    .card-footer123 {
      width: 70%;
    }
    .card-footer123 a {
      margin-bottom: 8px !important;
      cursor: pointer !important;
    }
    .card-footer123 p {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0 !important;
    }
    .mb {
      margin-bottom: 60px;
    }
    .box-content {
      background-color: #fff;
      border-radius: 4.87218px;
      margin: auto;
      text-align: center;
      position: relative;
      /* width: 357px;   */
      /* width: 290px;
  padding: 40px; */
      /* height: 305px; */
    }
    .box-content h5 {
      font-weight: 700;
      font-size: 24px;
      line-height: 22px;
      margin: 16px 0;
      color: black;
    }
    .card-footer123 {
      position: absolute;
      bottom: 10px;
    }
    .box-content p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      max-width: 230px;
      margin: auto;
      letter-spacing: 0.2px;
      font-family: "SF Pro Display";
      margin-bottom: 30px;
      color: #000000;
    }
    .box-content p a,
    .box-content h6 {
      font-family: "SF Pro Display";
      color: #42c0f5;
      letter-spacing: 0.2px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 16px 0;
    }
    .box-content hr {
      // border-top: unset;
      border-top: 1px solid rgba(0, 0, 0, 0.4);
      margin: 16px 0;
    }
  }
}
