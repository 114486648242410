.wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #F9FDFF;
  top: 0px;
  left: 0;
  padding: 0 var(--spacing-4);
  box-sizing: border-box;
  z-index: 1;
}

.card {
  max-width: 1280px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  margin: var(--spacing-4) auto;
  padding: var(--spacing-5);
  border: 1px solid #DFE0E1;
  border-radius: 4px;
  box-sizing: border-box;
}

.card img {
  height: 161px;
  width: 161px;
  margin: auto;
  border-radius: 50%;
  margin-bottom: var(--spacing-3);
}

.card h1 {
  margin: auto;
  color: var(--gray-1);
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  margin-bottom: var(--spacing-2);
}

.card h2 {
  margin: auto;
  color: var(--gray-1);
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: var(--spacing-3);
}

.form {
  width: 100%;
  max-width: 368px;
  margin: auto;
}

.buttonWrapper {
  margin-bottom: var(--spacing-1);
}